import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import StyledSmText from "../../common/components/styledSmText/StyledSmText";
import { content } from "../../data";
import { usePath } from "../../context/PathContext";

const MobileHeader = () => {

  const header = content?.menuItems?.header
  const currentYear = new Date().getFullYear();
  const [navShow, setNavShow] = useState(false);
  const pathname = usePath()

  const onToggleNav = () => {
    setNavShow((status) => {
      if (status) {
        document.body.style.overflow = "auto";
      } else {
        // Prevent scrolling
        document.body.style.overflow = "hidden";
      }
      return !status;
    });
  };

  const handleMobileNav = () => {
    onToggleNav()
  }

  useEffect(() => {
    if (navShow) {
      onToggleNav();
    }
  }, [pathname]);

  return (
    <div className="z-20 md:hidden">
      <button
        type="button"
        className="mt-2 text-xl leading-none bg-transparent rounded-full outline-none cursor-pointer focus:outline-none"
        aria-label="Toggle Menu"
        onClick={handleMobileNav}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="12" viewBox="0 0 17 12" fill="none">
          <path d="M16 10.6465H1C0.912835 10.6465 0.854416 10.6152 0.81783 10.5787C0.781244 10.5421 0.75 10.4836 0.75 10.3965C0.75 10.3093 0.781244 10.2509 0.81783 10.2143C0.854416 10.1777 0.912835 10.1465 1 10.1465H16C16.0872 10.1465 16.1456 10.1777 16.1822 10.2143C16.2188 10.2509 16.25 10.3093 16.25 10.3965C16.25 10.4836 16.2188 10.5421 16.1822 10.5787C16.1456 10.6152 16.0872 10.6465 16 10.6465Z" stroke="white" strokeWidth="1.5" />
          <path d="M16 5.94824H1C0.912835 5.94824 0.854416 5.917 0.81783 5.88041C0.781244 5.84383 0.75 5.78541 0.75 5.69824C0.75 5.61108 0.781244 5.55266 0.81783 5.51607C0.854416 5.47949 0.912835 5.44824 1 5.44824H16C16.0872 5.44824 16.1456 5.47949 16.1822 5.51607C16.2188 5.55266 16.25 5.61108 16.25 5.69824C16.25 5.78541 16.2188 5.84383 16.1822 5.88041C16.1456 5.917 16.0872 5.94824 16 5.94824Z" stroke="white" strokeWidth="1.5" />
          <path d="M16 1.25H1C0.912835 1.25 0.854416 1.21876 0.81783 1.18217C0.781244 1.14558 0.75 1.08717 0.75 1C0.75 0.912835 0.781244 0.854416 0.81783 0.81783C0.854416 0.781244 0.912835 0.75 1 0.75H16C16.0872 0.75 16.1456 0.781244 16.1822 0.81783C16.2188 0.854415 16.25 0.912834 16.25 1C16.25 1.08717 16.2188 1.14558 16.1822 1.18217C16.1456 1.21876 16.0872 1.25 16 1.25Z" stroke="white" strokeWidth="1.5" />
        </svg>
      </button>
      <div
        className={`fixed top-0 left-0 z-10 h-full w-[100vw] transform bg-black ${navShow ? "translate-x-[0%]" : "-translate-x-full"
          }`}
        style={{ transition: "all 0.5s cubic-bezier(.68,-0.55,.27,1.55)" }}
      >
        <div className="flex justify-end">
          <svg className="z-10 w-4 h-4 mt-4 mr-4 rounded" onClick={onToggleNav} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="15" cy="15" r="15" fill="#D1D1D1"></circle><path d="M21.6372 19.9289C22.1209 20.4131 22.1209 21.1662 21.6372 21.6503C21.3954 21.8924 21.0998 22 20.7774 22C20.4549 22 20.1593 21.8924 19.9175 21.6503L15 16.7281L10.0825 21.6503C9.84069 21.8924 9.54511 22 9.22265 22C8.90019 22 8.60461 21.8924 8.36276 21.6503C7.87908 21.1662 7.87908 20.4131 8.36276 19.9289L13.2802 15.0067L8.36276 10.0845C7.87908 9.60039 7.87908 8.84726 8.36276 8.36311C8.84645 7.87896 9.59885 7.87896 10.0825 8.36311L15 13.2853L19.9175 8.36311C20.4012 7.87896 21.1536 7.87896 21.6372 8.36311C22.1209 8.84726 22.1209 9.60039 21.6372 10.0845L16.7198 15.0067L21.6372 19.9289Z" fill="black"></path></svg>
        </div>
        <nav className="fixed top-0 left-0 h-full w-[100vw]">
          <div className="flex flex-col justify-around h-full gap-4">
            <div className='flex justify-center w-full mt-6'>
              <Link to="/" className="flex justify-center w-[60%]">
                <img className='h-full ' src='/assets/images/logo.png' alt='Logo' />
              </Link>
            </div>
            <div className='flex flex-col items-center gap-5'>
              {
                header?.map((item, index) => (
                  <Link className={`hover:text-yellow text-h4 ${pathname.path === item?.link ? "text-yellow" : "text-[#faefc3]"}`} key={index} to={item.link}>{item.menu}</Link>
                ))
              }
            </div>
            <div className="flex flex-col items-center gap-6">
              <div className='w-full'>
                <hr className='border-light-gray border-opacity-20' />
              </div>
              <div className='text-center w-[90%]'>
                <StyledSmText fontColor="text-light-gray">Copyright &#169;{currentYear} Functionised Blockchain Technology Inc. | All rights reserved.</StyledSmText>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default MobileHeader;
