import React, { useState } from "react";
import StyledXlText from "../../common/components/styledXlText/StyledXlText";

const Faqs = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ul className="flex flex-col gap-3">
      <li
        className={`overflow-hidden divide-y shadow-sm shadow-light-gray rounded-2xl ${isOpen ? 'bg-yellow bg-opacity-10 border-yellow border' : ''}`}
      >
        <details className="group border-l-[10px] border-yellow pl-4 md:pl-5">
          <summary
            className="relative flex w-[95%] md:w-full items-center gap-3 py-3 font-medium md:py-4 marker:content-none hover:cursor-pointer"
            onClick={toggleAccordion}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="currentColor"
              className={`absolute w-5 h-5 font-extrabold transition hidden md:block ${isOpen ? 'text-purple rotate-180' : ''} top-5 right-3`}
            >
              <path
                d="M17.2997 9.30001C17.483 9.48335 17.5747 9.71668 17.5747 10C17.5747 10.2833 17.483 10.5167 17.2997 10.7L12.6997 15.3C12.5997 15.4 12.4914 15.471 12.3747 15.513C12.258 15.5543 12.133 15.575 11.9997 15.575C11.8664 15.575 11.737 15.55 11.6117 15.5C11.487 15.45 11.383 15.3833 11.2997 15.3L6.69971 10.7C6.51637 10.5167 6.42471 10.2833 6.42471 10C6.42471 9.71668 6.51637 9.48335 6.69971 9.30001C6.88304 9.11668 7.11637 9.02501 7.39971 9.02501C7.68304 9.02501 7.91637 9.11668 8.09971 9.30001L11.9997 13.2L15.8997 9.30001C16.083 9.11668 16.3164 9.02501 16.5997 9.02501C16.883 9.02501 17.1164 9.11668 17.2997 9.30001Z"
                fill="currentColor"
              />
            </svg>
            <StyledXlText fontColor="text-dull-black !font-bold">{item.question}</StyledXlText>
          </summary>

          <article className="pb-4 text-sm w-[95%]">
            <div className="font-normal text-md font-inter font-dark-gray" dangerouslySetInnerHTML={{ __html: item.answer }} />
          </article>
        </details>
      </li>
    </ul>
  );
};

export default Faqs;
