import React from 'react'
import PerCategoryBlogPageSection from '../../../sections/Blogs/PerCategoryBlogPageSection/PerCategoryBlogPageSection'

const PerCategoryPage = () => {
    return (
        <div className='flex justify-center w-full'>
            <PerCategoryBlogPageSection />
        </div>
    )
}

export default PerCategoryPage
