import React from 'react';
import { content } from "../../data"
import { Link } from 'react-router-dom';
import { usePath } from '../../context/PathContext';
import MobileHeader from './MobileHeader';

const Header = () => {

    const header = content.menuItems.header;
    const pathname = usePath()

    return (
        <div className='w-[80%] lg:w-[70%] h-[60px] flex items-center justify-between'>
            <Link to="/">
                <img src="/assets/images/logo.png" alt="Logo" />
            </Link>
            <div className='hidden gap-8 md:flex'>
                {
                    header?.map((item, index) => (
                        <Link className={`hover:text-yellow ${pathname.path === item?.link ? "text-yellow" : "text-[#faefc3]"}`} key={index} to={item.link}>{item.menu}</Link>
                    ))
                }
            </div>
            <div className="flex md:hidden">
                <MobileHeader />
            </div>
        </div>
    )
}

export default Header
