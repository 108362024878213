import React, { useEffect, useState } from 'react';
import StyledH1Heading from '../../../common/components/styledH1Heading/StyledH1Heading';
import BlogsCard from '../../../components/blogsCard/BlogsCard';
import { callApi } from '../../../API/callApi';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import ReactPaginate from "react-paginate";
import { Icon } from '@iconify/react/dist/iconify.js';
import StyledH6Heading from '../../../common/components/styledH6Heading/StyledH6Heading';
import Button from '../../../components/Button/Button';
import CardSkeleton from '../../../components/cardSkeleton/CardSkeleton';

const BlogsSection = () => {
  const [blogsData, setBlogsData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNum, setPageNum] = useState(Number(searchParams.get('page')) || 1);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getAllBlogs = async () => {
    setLoading(true);
    try {
      const response = await callApi('blogs/get-blogs-list', 'get', null, {
        page: pageNum,
        perPage: 6
      });
      setBlogsData(response.data.data);
      setTotalPages(response.data.meta.totalPages);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.message === "Network Error") {
        navigate("/500");
      }
      console.log(error);
    }
  };

  useEffect(() => {
    getAllBlogs();
  }, [pageNum]);

  const handlePageClick = (data) => {
    setPageNum(data.selected + 1);
    navigate(`?page=${data.selected + 1}`, { replace: true });
  };

  useEffect(() => {
    const fetchPage = Number(searchParams.get('page')) || 1;
    setPageNum(fetchPage);
  }, [searchParams]);

  return (
    <div className="py-10 w-[90%] relative flex flex-col gap-5 items-center">
      <div className="w-full lg:w-[80%] py-10 text-center bg-yellow rounded-xl">
        <StyledH1Heading fontColor={"text-black"}>Blogs | Articles | News</StyledH1Heading>
        <p className="w-11/12 mx-auto font-inter text-grey sm:w-9/12 lg:w-8/12">
          Explore our latest blogs and articles on different categories
        </p>
      </div>
      {loading ? (
        <div className='grid w-full grid-cols-1 gap-5 pb-10 md:w-[80%] md:grid-cols-2 xl:grid-cols-3 place-content-center'>
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
        </div>
      ) : (
        blogsData.length === 0 ? (
          <div className='flex flex-col items-center justify-center h-full lg:w-[80%] gap-5 py-10'>
            <div className='flex justify-center w-full'>
              <img src='/assets/images/no-blog-found.png' alt='No Blog Found' />
            </div>
            <div className='flex justify-center w-full'>
              <StyledH6Heading fontColor="text-light-gray">No Blogs Found Yet</StyledH6Heading>
            </div>
            <Link className='flex justify-center w-full' to="/">
              <Button
                text="Go Back Home"
                bgColor="bg-yellow"
              />
            </Link>
          </div>
        ) :
          <div className='grid w-full grid-cols-1 gap-5 pb-10 md:w-[80%] md:grid-cols-2 xl:grid-cols-3 place-content-center'>
            {blogsData.map((item, index) => (
              <BlogsCard data={item} key={index} />
            ))}
          </div>
      )}
      <div className="w-full">
        {
          blogsData.length !== 0 && (
            <ReactPaginate
              previousLabel={<Icon icon="ooui:previous-ltr" width="15" height="15" />}
              nextLabel={<Icon icon="ooui:previous-rtl" width="15" height="15" />}
              breakLabel={"..."}
              pageCount={totalPages}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={`pagination`}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={pageNum - 1}
              previousClassName={`custom-previous-class ${pageNum === 1 && 'cursor-not-allowed'}`}
              nextClassName={`custom-next-class ${pageNum === totalPages && 'cursor-not-allowed'}`}
            />
          )}
      </div>
    </div>
  )
}

export default BlogsSection;
