import React from 'react'
import PrivacyPolicySection from '../../sections/PrivacyPolicy/PrivacyPolicySection/PrivacyPolicySection'

const PrivacyPolicyPage = () => {
    return (
        <div>
            <PrivacyPolicySection />
        </div>
    )
}

export default PrivacyPolicyPage
