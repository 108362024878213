import React from 'react'
import StyledH3Heading from '../../../common/components/styledH3Heading/StyledH3Heading'
import StyledXlText from '../../../common/components/styledXlText/StyledXlText'

const WhatWeProvideSec1 = () => {
    return (
        <div className='flex flex-col lg:flex-row gap-10 w-[90%] items-center py-10 lg:py-20'>
            <div className='bg-yellow rounded-[18px] flex flex-col gap-3 py-10 px-6 lg:w-[50%] h-max'>
                <div>
                    <StyledH3Heading fontColor="text-black">Be The Healthiest You've Ever Been</StyledH3Heading>
                </div>
                <div>
                    <StyledXlText fontColor="text-black">Transform your health journey with Functionised Integrative Tokens (FITS), the premier app that incentivizes your daily activities with cutting-edge digital currency, empowering you to unlock exclusive rewards. <br /><br />By prioritizing your well-being, you're not just investing in yourself – you're fostering a healthier society, boosting productivity, and saving billions in healthcare costs. <br /><br />Download now and start reaping the rewards of your active lifestyle!</StyledXlText>
                </div>
            </div>
            <div className='lg:w-[50%] flex justify-center'>
                <img src='/assets/images/img-whatWeProvideSec1.png' alt='Section#2' />
            </div>
        </div>
    )
}

export default WhatWeProvideSec1
