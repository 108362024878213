import React from 'react'
import StyledH3Heading from '../../../common/components/styledH3Heading/StyledH3Heading'
import StyledXlText from '../../../common/components/styledXlText/StyledXlText'

const WhatWeProvideSec2 = () => {
    return (
        <div className='flex flex-col lg:flex-row-reverse gap-10 w-[90%] items-center py-10 lg:py-20'>
            <div className='bg-yellow rounded-[18px] flex flex-col gap-3 py-10 px-6 lg:w-[50%] h-max'>
                <div>
                    <StyledH3Heading fontColor="text-black">The Ultimate App</StyledH3Heading>
                </div>
                <div>
                    <StyledXlText fontColor="text-black">FITS, the ultimate free app, ingeniously incentivizes your daily activities with cutting-edge digital currency, enabling you to indulge in trendy products, make impactful charity contributions or create additional income for yourself. Prioritize your health and watch as you become a catalyst for societal well-being, amplifying productivity and contributing to billions in healthcare savings. <br /><br />Your movement holds immense value, and FITS ensures you receive your well-deserved share. Download now and seize the opportunity to transform your health while earning rewards!</StyledXlText>
                </div>
            </div>
            <div className='lg:w-[50%] flex justify-center'>
                <img src='/assets/images/img-whatWeProvideSec2.png' alt='Section#2' />
            </div>
        </div>
    )
}

export default WhatWeProvideSec2
