import React from "react";
import { Link } from "react-router-dom";

// Files Import
import StyledMdText from "../../../common/components/styledMdText/StyledMdText";
import StyledLgText from "../../../common/components/styledLgText/StyledLgText";
import StyledH1Heading from "../../../common/components/styledH1Heading/StyledH1Heading";
import StyledH4Heading from "../../../common/components/styledH4Heading/StyledH4Heading";

const TermsNConditionSection = () => {
  return (
    <div className="py-5 lg:py-10">
      <div className="px-5 py-2 text-center lg:py-4 md:py-5">
        <StyledH1Heading fontColor="text-yellow">
          Terms & Condition
        </StyledH1Heading>
      </div>
      <div className="flex flex-col gap-5 border border-yellow rounded-[18px] py-5 lg:py-10 mx-auto w-11/12 md:w-3/4 lg:w-[62%]">
        {/* Article 1 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            PLEASE READ THESE TERMS OF USE BEFORE USING THE WEBSITES.
          </StyledH4Heading>
          <StyledH4Heading fontColor="text-black">
            1. Acceptance of the Terms of Use
          </StyledH4Heading>

          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              These terms of use are entered into by and between you and
              Functionised Blockchain Technology (the “Foundation”, “we”, or
              “us”). The following terms and conditions, together with any
              documents they expressly incorporate by reference (collectively,
              these “Terms of Use”), govern your access to and use of any
              website published by the Foundation, including, but not limited
              to, any content, functionality, and services offered on or through{" "}
              <Link target="_blank" to="https://getfits.io" className="text-yellow">getfits.io</Link> or any other
              website created and/ or owned by Functionised Blockchain
              Technology (the “Websites”).
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              Please read the Terms of Use carefully before you start to use the
              Websites. By using the Websites or by clicking to accept or agree
              to the Terms of Use when this option is made available to you, you
              accept and agree to be bound and abide by these Terms of Use in
              addition to:
            </StyledLgText>
            <div className="ml-5 lg:ml-10 text-dark-gray">
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Our Privacy Policy, incorporated herein by reference{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Our Cookie Policy, incorporated herein by reference{" "}
                </StyledMdText>
              </li>
            </div>
            <StyledLgText fontColor="text-dark-gray">
              If you do not agree to these Terms of Use, you must not access or
              use the Websites.
            </StyledLgText>
          </div>
        </div>
        {/* Article 2 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            2. Who May Use the Websites
          </StyledH4Heading>

          <div>
            <StyledLgText fontColor="text-dark-gray">
              The Websites are offered and available to users who are 13 years
              of age or older. The Websites are not intended for children under
              13 years of age. By using the Websites, you represent and warrant
              that you (i) are 13 years of age or older, (ii) are not barred to
              use the Websites under any applicable law, and (iii) are using the
              Websites only for your own personal use. If you do not meet these
              requirements, you must not access or use the Websites.
            </StyledLgText>
          </div>
        </div>
        {/* Article 3 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            3. Changes to the Terms of Use
          </StyledH4Heading>

          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              We may revise and update these Terms of Use from time to time in
              our sole discretion. All changes are effective immediately when we
              post them.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              Your continued use of the Websites following the posting of
              revised Terms of Use means that you accept and agree to the
              changes. You are expected to check this page frequently so you are
              aware of any changes, as they are binding on you.
            </StyledLgText>
          </div>
        </div>
        {/* Article 4 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            4. Accessing the Websites and Account Security
          </StyledH4Heading>

          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              We reserve the right to withdraw or amend the Websites, and any
              service or material we provide on the Websites, in our sole
              discretion without notice. We do not guarantee that our Websites
              or any content on them will always be available or be interrupted.
              We will not be liable if for any reason all or any part of the
              Websites are unavailable at any time or for any period. From time
              to time, we may restrict access to some parts of the Websites, or
              entire Websites, to users.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              You are responsible for:
            </StyledLgText>
            <div className="ml-5 lg:ml-10 text-dark-gray">
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Making all arrangements necessary for you to have access to
                  the Websites{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Ensuring that all persons who access the Websites through your
                  internet connection are aware of these Terms of Use and comply
                  with them.{" "}
                </StyledMdText>
              </li>
            </div>
            <StyledLgText fontColor="text-dark-gray">
              To access the Websites or some of the resources it offers, you may
              be asked to provide certain registration details or other
              information. It is a condition of your use of the Websites that
              all the information you provide on the Websites is correct,
              current, and complete. You agree that all information you provide
              to use the Websites, including, but not limited to, using any
              interactive features on the Websites, is governed by our Privacy
              Policy, and you consent to all actions we may take with respect to
              your information that are consistent with our Privacy Policy.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              You should use particular caution when inputting personal
              information onto the Websites on a public or shared computer so
              that others are not able to view or record your personal
              information.
            </StyledLgText>
          </div>
        </div>
        {/* Article 5 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            5. Intellectual Property Rights
          </StyledH4Heading>

          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              The Websites and their entire contents, features, and
              functionality (including but not limited to all information,
              software, text, displays, images, video and audio, and the design,
              selection, and arrangement thereof), are owned by the Foundation,
              its licensors or other providers of such material and are
              protected by copyright, trademark, patent, trade secret, and other
              intellectual property or proprietary rights laws.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              Unless otherwise marked, (a) all material, data, and information
              on the Websites, such as data files, text, music, audio files or
              other sounds, photographs, videos, or other images, but excluding
              any software or computer code (collectively, the “Non-Code
              Content”) are licensed under the Creative Commons Attribution 4.0
              International License; and (b) all software or computer code
              (collectively, the “Code Content”) are licensed under the MIT
              License.
            </StyledLgText>
          </div>
        </div>
        {/* Article 6 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            6. Trademarks
          </StyledH4Heading>

          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              The Foundation name, the terms FITS, Get FITS, Functionised,
              Functionised Blockchain Technology, FIT, the Foundation logo and
              all related names, logos, product and service names, designs and
              slogans are trademarks of the Foundation or its affiliates or
              licensors. You must not use such marks without the prior written
              permission of the Foundation. All other names, logos, product and
              service names, designs and slogans on this Websites are the
              trademarks of their respective owners.
            </StyledLgText>
          </div>
        </div>
        {/* Article 7 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            7. Prohibited Uses
          </StyledH4Heading>

          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              You may use the Websites only for lawful purposes and in
              accordance with these Terms of Use. You agree not to use the
              Websites:
            </StyledLgText>
            <div className="ml-5 lg:ml-10 text-dark-gray">
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  In any way that violates any applicable federal, state, local,
                  or international law or regulation (including, without
                  limitation, any laws regarding the export of data or software
                  to and from the US or other countries){" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  For the purpose of exploiting, harming, or attempting to
                  exploit or harm minors in any way by exposing them to
                  inappropriate content, asking for personally identifiable
                  information or otherwise{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  To send, knowingly receive, upload, download, use, or re-use
                  any material which does not comply with these Terms of Use{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  To transmit, or procure the sending of, any advertising or
                  promotional material without our prior written consent,
                  including any “junk mail”, “chain letter”, “spam”, or any
                  other similar solicitation{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  To impersonate or attempt to impersonate the Foundation, a
                  Foundation employee, another user, or any other person or
                  entity (including, without limitation, by using e-mail
                  addresses or screen names associated with any of the foregoing{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  To engage in any other conduct that restricts or inhibits
                  anyone's use or enjoyment of the Websites, or which, as
                  determined by us, may harm the Foundation or users of the
                  Websites or expose them to liability.{" "}
                </StyledMdText>
              </li>
            </div>
            <StyledLgText fontColor="text-dark-gray">
              Additionally, you agree not to:
            </StyledLgText>
            <div className="ml-5 lg:ml-10 text-dark-gray">
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Use the Websites in any manner that could disable, overburden,
                  damage, or impair the Websites or interfere with any other
                  party’s use of the Websites, including their ability to engage
                  in real time activities through the Websites{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Use the Websites in any manner that could disable, overburden,
                  damage, or impair the Websites or interfere with any other
                  party’s use of the Websites, including their ability to engage
                  in real time activities through the Websites{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Use any manual process to monitor or copy any of the material
                  on the Websites or for any other unauthorized purpose without
                  our prior written consent{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Use any device, software or routine that interferes with the
                  proper working of the Websites{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Introduce any viruses, trojan horses, worms, logic bombs, or
                  other material which is malicious or technologically harmful{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Attempt to gain unauthorized access to, interfere with,
                  damage, or disrupt any parts of the Websites, the server(s) on
                  which the Websites is stored, or any server, computer or
                  database connected to the Websites{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Attack the Websites via a denial-of-service attack or a
                  distributed denial-of-service attack{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Attack the Websites via a denial-of-service attack or a
                  distributed denial-of-service attack{" "}
                </StyledMdText>
              </li>
            </div>
          </div>
        </div>
        {/* Article 8 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            8. Reliance on Information Posted
          </StyledH4Heading>

          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              The information presented on or through the Websites is made
              available solely for general information purposes. We do not
              warrant the accuracy, completeness or usefulness of this
              information. Any reliance you place on such information is
              strictly at your own risk. We disclaim all liability and
              responsibility arising from any reliance placed on such materials
              by you or any other visitor to the Websites, or by anyone who may
              be informed of any of its contents.
            </StyledLgText>

            <StyledLgText fontColor="text-dark-gray">
              The Websites may include content provided by third parties,
              including materials provided by other users, bloggers and
              third-party licensors, syndicators, aggregators, and/or reporting
              services. All statements and/or opinions expressed in these
              materials, and all articles and responses to questions and other
              content, other than the content provided by the Foundation, are
              solely the opinions and the responsibility of the person or entity
              providing those materials. These materials do not necessarily
              reflect the opinion of the Foundation. We are not responsible, or
              liable to you or any third party, for the content or accuracy of
              any materials provided by any third parties.
            </StyledLgText>
          </div>
        </div>
        {/* Article 9 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            9. Changes to the Websites
          </StyledH4Heading>

          <div>
            <StyledLgText fontColor="text-dark-gray">
              We may update the content on the Websites from time to time, but
              its content is not necessarily complete or up-to-date. Any of the
              material on the Websites may be out of date at any given time, and
              we are under no obligation to update such material.
            </StyledLgText>
          </div>
        </div>
        {/* Article 10 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            10. Information About You and Your Visits to the Websites
          </StyledH4Heading>

          <div>
            <StyledLgText fontColor="text-dark-gray">
              All information we collect on the Websites is subject to our
              Privacy Policy. By using the Websites, you consent to all actions
              that may be taken by us with respect to your information in
              compliance with the Privacy Policy.
            </StyledLgText>
          </div>
        </div>
        {/* Article 11 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            11. Online Purchases and Other Terms and Conditions
          </StyledH4Heading>

          <div>
            <StyledLgText fontColor="text-dark-gray">
              Additional terms and conditions may also apply to specific
              portions, services or features of the Websites, including the
              registration and sponsorship for conference events. All such
              additional terms and conditions are hereby incorporated by this
              reference into these Terms of Use. In the event of terms that are
              directly conflicting between these Terms of Use and terms of
              conditions for the registration or sponsorship of a conference
              event, the terms and conditions for the event shall control.
            </StyledLgText>
          </div>
        </div>
        {/* Article 12 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            12. Linking to the Websites and Social Media Features
          </StyledH4Heading>

          <div>
            <StyledLgText fontColor="text-dark-gray">
              You may link to our homepage, provided you do so in a way that is
              fair and legal and does not damage our reputation or take
              advantage of it, but you must not establish a link in such a way
              as to suggest any form of association, approval or endorsement on
              our part without our express written consent.
            </StyledLgText>
          </div>
        </div>
        {/* Article 13 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            13. Links from the Websites
          </StyledH4Heading>

          <div>
            <StyledLgText fontColor="text-dark-gray">
              If the Websites contain links to other sites and resources
              provided by third parties, these links are provided for your
              convenience only. This includes links contained in advertisements,
              including banner advertisements and sponsored links. We have no
              control over the contents of those sites or resources, and accept
              no responsibility for them or for any loss or damage that may
              arise from your use of them. If you decide to access any of the
              third party websites linked to the Websites, you do so entirely at
              your own risk and subject to the terms and conditions of use for
              such websites. We reserve the right to withdraw linking permission
              without notice.
            </StyledLgText>
          </div>
        </div>
        {/* Article 14 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            14. Geographic Restrictions
          </StyledH4Heading>

          <div>
            <StyledLgText fontColor="text-dark-gray">
              The owner of the Websites is based in Switzerland. We make no
              claims that the Websites or any of its content is accessible or
              appropriate outside of Switzerland. Access to the Websites may not
              be legal by certain persons or in certain countries. If you access
              the Websites from outside Switzerland, you do so on your own
              initiative and are responsible for compliance with local laws.
            </StyledLgText>
          </div>
        </div>
        {/* Article 15 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            15. Disclaimer of Warranties
          </StyledH4Heading>

          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              You understand that we cannot and do not guarantee or warrant that
              files available for downloading from the internet or the Websites
              will be free of viruses or other destructive code. You are
              responsible for implementing sufficient procedures and checkpoints
              to satisfy your particular requirements for anti-virus protection
              and accuracy of data input and output, and for maintaining a means
              external to our site for any reconstruction of any lost data. WE
              WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED
              DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY
              HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER
              PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF
              THE WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
              WEBSITES OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR
              ON ANY WEBSITES LINKED TO IT.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              YOUR USE OF THE WEBSITES, THEIR CONTENT AND ANY SERVICES OR ITEMS
              OBTAINED THROUGH THE WEBSITES IS AT YOUR OWN RISK. THE WEBSITES,
              THEIR CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE
              WEBSITES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS,
              WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.
              NEITHER THE FOUNDATION NOR ANY PERSON ASSOCIATED WITH THE
              FOUNDATION MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO
              THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
              AVAILABILITY OF THE WEBSITES. WITHOUT LIMITING THE FOREGOING,
              NEITHER THE FOUNDATION NOR ANYONE ASSOCIATED WITH THE FOUNDATION
              REPRESENTS OR WARRANTS THAT THE WEBSITES, THEIR CONTENT OR ANY
              SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITES WILL BE ACCURATE,
              RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE
              CORRECTED, THAT THE WEBSITES OR THE SERVER(S) THAT MAKES THEM
              AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT
              THE WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
              WEBSITES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              THE FOUNDATION HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND,
              WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT
              NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
              NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              SOME JURISDICTIONS DO NOT ALLOW EXCLUSION OF WARRANTIES OR
              LIMITATIONS ON THE DURATION OF IMPLIED WARRANTIES, SO THE ABOVE
              DISCLAIMER MAY NOT APPLY TO YOU IN THEIR ENTIRETIES, BUT WILL
              APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
            </StyledLgText>
          </div>
        </div>
        {/* Article 16 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            16. Limitation on Liability
          </StyledH4Heading>

          <div>
            <StyledLgText fontColor="text-dark-gray">
              IN NO EVENT WILL THE FOUNDATION, ITS AFFILIATES OR THEIR
              LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR
              DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL
              THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR
              INABILITY TO USE, THE WEBSITES, ANY WEBSITES LINKED TO THEM, ANY
              CONTENT ON THE WEBSITES OR SUCH OTHER WEBSITES OR ANY SERVICES OR
              ITEMS OBTAINED THROUGH THE WEBSITES OR SUCH OTHER WEBSITES,
              INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL
              OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL
              INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE,
              LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF
              USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT
              (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF
              FORESEEABLE. THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH
              CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW WHICH MAY
              INCLUDE FRAUD.
            </StyledLgText>
          </div>
        </div>
        {/* Article 17 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            17. Indemnification
          </StyledH4Heading>

          <div>
            <StyledLgText fontColor="text-dark-gray">
              You agree to defend, indemnify, and hold harmless the Foundation,
              its affiliates, licensors, and service providers, and its and
              their respective officers, directors, employees, contractors,
              agents, licensors, suppliers, successors, and assigns from and
              against any claims, liabilities, damages, judgments, awards,
              losses, costs, expenses, or fees (including reasonable attorneys’
              fees) arising out of or relating to your violation of these Terms
              of Use or your use of the Websites, including, but not limited to,
              any use of the Websites’ content, services and products other than
              as expressly authorized in these Terms of Use or your use of any
              information obtained from the Websites.
            </StyledLgText>
          </div>
        </div>
        {/* Article 18 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            18. Governing Law and Jurisdiction
          </StyledH4Heading>

          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              All matters relating to the Websites and these Terms of Use and
              any dispute or claim arising therefrom or related thereto (in each
              case, including non-contractual disputes or claims), shall be
              governed by and construed in accordance with the internal laws of
              Switzerland without giving effect to any choice or conflict of law
              provision or rule (whether of Switzerland or any other
              jurisdiction).
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              Any legal suit, action or proceeding arising out of, or related
              to, these Terms of Use or the Websites shall be instituted
              exclusively in the Switzerland in the Kanton of Zug although we
              retain the right to bring any suit, action or proceeding against
              you for breach of these Terms of Use in your country of residence
              or any other relevant country. You waive any and all objections to
              the exercise of jurisdiction over you by such courts and to venue
              in such courts.
            </StyledLgText>
          </div>
        </div>
        {/* Article 19 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            19. Waiver and Severability
          </StyledH4Heading>

          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              No waiver by the Foundation of any term or condition set forth in
              these Terms of Use shall be deemed a further or continuing waiver
              of such term or condition or a waiver of any other term or
              condition, and any failure of the Foundation to assert a right or
              provision under these Terms of Use shall not constitute a waiver
              of such right or provision.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              If any provision of these Terms of Use is held by a court or other
              tribunal of competent jurisdiction to be invalid, illegal, or
              unenforceable for any reason, such provision shall be eliminated
              or limited to the minimum extent such that the remaining
              provisions of the Terms of Use will continue in full force and
              effect.
            </StyledLgText>
          </div>
        </div>
        {/* Article 20 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            20. Entire Agreement
          </StyledH4Heading>

          <div>
            <StyledLgText fontColor="text-dark-gray">
              The Terms of Use, our Privacy Policy and terms of conditions for
              the registration of events constitute the sole and entire
              agreement between you and the Foundation with respect to the
              Websites and supersede all prior and contemporaneous
              understandings, agreements, representations and warranties, both
              written and oral, with respect to the Websites.
            </StyledLgText>
          </div>
        </div>
        {/* Article 21 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            21. Your Comments and Concerns
          </StyledH4Heading>

          <div>
            <StyledLgText fontColor="text-dark-gray">
              The Websites are operated by the Foundation. All other feedback,
              comments, requests for technical support and other communications
              relating to the Websites should be directed to:{" "}
              <Link
                target="_blank"
                className="underline text-yellow"
                to={"mailto:support@getfits.io"}
              >
                support@getfits.io
              </Link>
            </StyledLgText>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsNConditionSection;
