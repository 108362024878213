import React from 'react'

const EarnRewardsSection = () => {
  return (
    <div className='flex justify-center w-full py-5 bg-dull-black'>
      <img src='/assets/images/earnRewardsSolid.png' alt='Earn Rewards' />
    </div>
  )
}

export default EarnRewardsSection
