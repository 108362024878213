import React from 'react'
import StyledH1Heading from '../../../common/components/styledH1Heading/StyledH1Heading'
import StyledLgText from '../../../common/components/styledLgText/StyledLgText'
import { Link } from 'react-router-dom'

const SocialCommunitySection = () => {
    return (
        <div className='lg:w-[90%] pt-10 lg:pt-40 lg:pb-5 relative w-full'>
            <div className='lg:rounded-[16px] flex flex-col-reverse lg:flex-row items-center px-5 lg:px-10 gap-10 bg-yellow relative lg:h-[350px]'>
                <div className='lg:w-[55%] flex justify-center'>
                    <img width="534px" height="204px" className='lg:absolute lg:bottom-5' src='/assets/images/img-socialCommunity.png' alt='Social Community' />
                </div>
                <div className='lg:w-[45%] flex flex-col items-center lg:items-start gap-1 pt-10 lg:pt-0'>
                    <div>
                        <StyledH1Heading fontColor="text-black !leading-none">Coming Soon</StyledH1Heading>
                    </div>
                    <div>
                        <StyledLgText fontColor="text-black">Get fit with $FITS.</StyledLgText>
                    </div>
                    <div className='flex gap-2 pt-5 lg:pt-10'>
                        <Link target='__blank' to="https://play.google.com/store/games?hl=en&gl=US">
                            <img width="211.502px" height="64.847px" src='/assets/images/googleplay.png' alt='Google Play Store' />
                        </Link>
                        <Link target='__blank' to="https://www.apple.com/app-store/">
                            <img width="211.502px" height="64.847px" src='/assets/images/applestore.png' alt='Google Play Store' />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SocialCommunitySection
