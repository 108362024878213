import React from 'react'
import ServerErrorSection from '../../sections/ServerError/ServerErrorSection/ServerErrorSection'

const ServerErrorPage = () => {
    return (
        <div className="flex items-center justify-center w-full py-10">
            <ServerErrorSection />
        </div>
    )
}

export default ServerErrorPage
