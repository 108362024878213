import React from 'react';

const CardSkeleton = () => {
    return (
        <div className='w-full flex flex-col h-full gap-3 grid-cols-1 bg-white rounded-[18px] border border-[#e0e0e0] p-3 lg:p-4 cardDropShadow animate-pulse'>
            <div className='w-full rounded-[18px] h-[200px] bg-gray-200'></div>
            <div className='flex flex-col gap-1'>
                <div className='w-1/2 h-4 bg-gray-200 rounded'></div>
                <div className='w-3/4 h-6 bg-gray-200 rounded'></div>
            </div>
            <div className='w-1/3 h-4 bg-gray-200 rounded'></div>
        </div>
    );
}

export default CardSkeleton;
