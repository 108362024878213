import React from "react";
import { Link } from "react-router-dom";

// Files Import
import StyledMdText from "../../../common/components/styledMdText/StyledMdText";
import StyledLgText from "../../../common/components/styledLgText/StyledLgText";
import StyledH1Heading from "../../../common/components/styledH1Heading/StyledH1Heading";
import StyledH4Heading from "../../../common/components/styledH4Heading/StyledH4Heading";
import StyledH5Heading from "../../../common/components/styledH5Heading/StyledH5Heading";

const PrivacyPolicySection = () => {
  return (
    <div className="py-5 lg:py-10">
      <div className="px-5 py-2 text-center lg:py-4 md:py-5">
        <StyledH1Heading fontColor="text-yellow">
          Privacy Policy
        </StyledH1Heading>
      </div>
      <div className="flex flex-col gap-5 border border-yellow rounded-[18px] py-5 lg:py-10 mx-auto w-11/12 md:w-3/4 lg:w-[62%]">
        {/* Article 1 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            1. General information
          </StyledH4Heading>

          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              Functionised Blockchain Technology Inc (the “Foundation”, “we”, or
              “us”) is the operator of any Website published by the Foundation
              (the “Websites”).
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              As the operator of the Websites, we take the protection of your
              personal data very seriously. We collect, process, and use your
              personal data in accordance with this privacy policy and in
              compliance with the Swiss Federal Act on Data Protection (“FADP”),
              the Swiss Ordinance to the Federal Act on Data Protection
              (“OFADP”), and the General European Data Protection Regulation
              (“GDPR”).
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              This privacy policy (“Privacy Policy”) will provide you with
              information about the collection, processing and use of your
              personal data when using the Websites.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              In case you provide us with the personal data of third persons
              (such as family members or work colleagues) you should make sure
              that these persons are familiar with this Privacy Policy and you
              should only share their personal data if you have permission to do
              so and ensure that his personal data is correct.
            </StyledLgText>
          </div>
        </div>
        {/* Article 2 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            2. Responsible Person
          </StyledH4Heading>

          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              For any matters, relating to data protection you may contact{" "}
              <Link
                className="text-yellow underline"
                to="mailto:notices@getfits.io"
              >
                notices@getfits.io
              </Link>
            </StyledLgText>
          </div>
        </div>
        {/* Article 3 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            3. Data processing in connection with the Websites
          </StyledH4Heading>
          {/* Article 3 (3.1) */}
          <StyledH5Heading fontColor="text-black">
            3.1 Visiting our Websites
          </StyledH5Heading>

          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              When you visit our Websites, the hosting provider(s) of our
              Websites may automatically collect and store various information
              in server log files that your browser transmits to us. The
              information/data mentioned is neither assigned to specific persons
              nor linked to data from other sources. The following technical
              data may be recorded by us, as usual with every connection with a
              web server, without your intervention, and stored by us until
              automatic deletion after no later than two days:
            </StyledLgText>
            <div className="ml-5 lg:ml-10 text-dark-gray">
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Woocommerce cart data{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Unique identification code for Woocommerce customer{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Anonymized IP addresses{" "}
                </StyledMdText>
              </li>
            </div>
            <StyledLgText fontColor="text-dark-gray">
              Any collection and processing of this technical data is for the
              purpose of enabling the use of our Websites, continuously ensuring
              system security and stability, optimising our Websites, and for
              internal statistical purposes. This is our legitimate interest in
              the processing of data in the sense of Art. 6 Par. 1 lit. f GDPR.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              Furthermore, the IP addresses may be evaluated, together with
              other data, in case of attacks on the network infrastructure or
              other unauthorised use or misuse of the Websites, for the purpose
              of intelligence and protection, and if appropriate, used in
              criminal proceedings for identification and civil and criminal
              proceedings against the relevant users. This is our legitimate
              interest in the processing of data in the sense of Art. 6 Par. 1
              lit. f GDPR.
            </StyledLgText>
          </div>
          {/* Article 3 (3.2) */}
          <StyledH5Heading fontColor="text-black">
            3.2 Use of Website Cookies
          </StyledH5Heading>

          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              The Websites may use cookies. Cookies are text files that are
              stored in a computer system via an Internet browser. More detailed
              information on cookies and how they work can be found at:{" "}
              <Link
                target="_blank"
                className="text-yellow underline"
                to={"https://allaboutcookies.org/"}
              >
                www.allaboutcookies.org
              </Link>
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              Many Internet sites and servers use cookies. Many cookies contain
              a so-called cookie ID. A cookie ID is a unique identifier of the
              cookie. It consists of a character string through which Internet
              pages and servers can be assigned to the specific Internet browser
              in which the cookie was stored. This allows visited Internet sites
              and servers to differentiate the individual browser of the data
              subject from other Internet browsers that contain other cookies. A
              specific Internet browser can be recognized and identified using
              the unique cookie ID.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              Through the use of cookies, the Foundation may provide the users
              of the Websites with more user-friendly services that would not be
              possible without the cookie setting.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              Cookies may allow us, as previously mentioned, to recognize our
              Website users. The purpose of this recognition is to make it
              easier for users to utilize our Websites. The Website user that
              uses cookies, e.g. does not have to enter access data each time
              the Website is accessed, because this is taken over by the
              Website, and the cookie is thus stored on the user's computer
              system.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              You may, at any time, prevent the setting of cookies through our
              Websites by means of a corresponding setting of the Internet
              browser used, and may thus permanently deny the setting of
              cookies. Furthermore, previously set cookies may be deleted at any
              time via an Internet browser or other software programs. This is
              possible in all popular Internet browsers. If the data subject
              deactivates the setting of cookies in the Internet browser used,
              it may not be possible to use all the functions of our Websites.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              For detailed information on the cookies we use and the purposes
              for which we use them, see our Cookie Policy.
            </StyledLgText>
          </div>
          {/* Article 3 (3.3) */}
          <StyledH5Heading fontColor="text-black">
            3.3 Matomo Analytics
          </StyledH5Heading>

          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              The Websites may use Matomo, an open source web analytics platform
              ("Matomo Analytics"). Where this is true, the information
              generated by the cookies about your use of the Websites (including
              your automatically anonymized IP address) may be stored on the
              servers of our hosting provider(s) in the United States. For more
              information about Matomo Analytics, you can access a demo version
              at:{" "}
              <Link
                target="_blank"
                className="text-yellow underline"
                to={"https://demo.matomo.cloud"}
              >
                demo.matomo.cloud
              </Link>
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              Before the data is stored, the IP address will be abbreviated by
              activating IP anonymization (anonymizeIP) on this Website.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              In addition to the data listed in section 3.1, we may receive the
              following information because of Matomo Analytics:
            </StyledLgText>
            <div className="ml-5 lg:ml-10 text-dark-gray">
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Usage data{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Navigation path{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Length of stay on the Website{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Returning or new user{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  End device{" "}
                </StyledMdText>
              </li>
            </div>
            <StyledLgText fontColor="text-dark-gray">
              We may use this technical data to analyze your use of the Websites
              by compiling reports on Website activity for the understanding
              and/or optimizing of our Websites and/or for internal statistical
              purposes. We do not use the information and personal data
              collected by Matomo to identify individuals unless we become aware
              of specific indications of illegal use.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              Any processing of this technical personal data helps us to
              identify what is working and what is not on our Websites and how
              we may improve our Websites. Without any data thus received, we
              may not be able to provide you the service we are currently
              offering to you. Your data will be used only to improve the user
              experience on our Websites and help you find the information you
              are looking for. This is our legitimate interest in the processing
              of data in the sense of Art. 6 Par. 1 lit. f GDPR.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              You can opt out of being tracked by Matomo Analytics and prevent
              the website from analysing the actions you take using the website.
              This will prevent us from learning from your actions and creating
              a better website experience for you and other users.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              You are not opted out. Uncheck this box to opt-out.
            </StyledLgText>
          </div>
          {/* Article 3 (3.4) */}
          <StyledH5Heading fontColor="text-black">
            3.4 Opening an account for the FITS Web Forum
          </StyledH5Heading>

          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              To access our forums, you must set up an account and provide us
              with your username, e-mail address, skype name, and password.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              The collected data, which you have voluntarily provided, is used
              for the purpose of providing your password-protected access to
              your base data we have stored. The legal basis for
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              Processing the data for this purpose lies in the consent you have
              provided in accordance with Art. 6 Par. 1 lit. a GDPR.
            </StyledLgText>
          </div>
          {/* Article 3 (3.5) */}
          <StyledH5Heading fontColor="text-black">
            3.5 Contact possibility via the Websites
          </StyledH5Heading>

          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              You may contact us via our Websites’ contact page or by e-mail to
              the following e-mail address:{" "}
              <Link
                target="_blank"
                className="text-yellow underline"
                to={"mailto:support@getfits.io"}
              >
                support@getfits.io
              </Link>
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              For this, we require the following information: Name, Subject,
              E-Mail address, message.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              We use this data, which you may give voluntarily, only in order to
              answer your contact question or to reply to your e-mail in the
              best possible manner. Therefore, the processing of this data is in
              our legitimate interest in accordance with Art. 6 Par. 1 lit. f
              GDPR and you have provided consent in accordance with Art. 6 Par.
              1 lit. a GDPR.
            </StyledLgText>
          </div>
          {/* Article 3 (3.6) */}
          <StyledH5Heading fontColor="text-black">
            3.6 Registration for our newsletter (if relevant)
          </StyledH5Heading>

          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              The receipt of our newsletter requires registration. For this you
              must provide your name and e-mail address. By registering, you
              give us your consent to process the given data in order to
              periodically send the newsletter to the address you have given.
              This consent constitutes the legal basis for our processing of
              your e-mail address in the sense of Art. 6 Par. 1 lit. a GDPR. All
              information gathered this way will never be passed on or sold to
              any third party.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              The receipt of our newsletter requires registration. For this you
              must provide your name and e-mail address. By registering, you
              give us your consent to process the given data in order to
              periodically send the newsletter to the address you have given.
              This consent constitutes the legal basis for our processing of
              your e-mail address in the sense of Art. 6 Par. 1 lit. a GDPR. All
              information gathered this way will never be passed on or sold to
              any third party.
            </StyledLgText>
          </div>
          {/* Article 3 (3.7) */}
          <StyledH5Heading fontColor="text-black">
            3.7 Social Media
          </StyledH5Heading>

          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              We may use plug-ins from social networks such as Blog, GitHub,
              YouTube, Reddit, Gitter, Twitter, StackExchange, Facebook, or
              Meetups on our Websites. When you activate them by clicking on
              them, the operators of the respective social networks may record
              that you are on our Websites and may use this information. This
              processing of your personal data lays in the responsibility of
              these individual social media platforms and occurs according to
              their privacy policy. Please check with these individual social
              media platforms regarding their privacy policies. The Foundation
              is not responsible for data collected by these individual social
              media platforms. We only use these platforms to inform our
              community of updates and answer user questions.
            </StyledLgText>
          </div>
          {/* Article 3 (3.8) */}
          <StyledH5Heading fontColor="text-black">
            3.8 Event Registration
          </StyledH5Heading>

          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              To register for any event organized by the Foundation through our
              Websites, we may require the following registration data: First
              and last name, language, company affiliation, company title,
              credit card information, e-mail address.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              We may use this information as well as other information you
              voluntarily provide (e.g. preferences, comments) only in order to
              execute the reservation agreement, unless otherwise stated in this
              Privacy Policy or you have not specifically consented thereto.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              We may process the data by name in order to record your
              reservation as you have requested, to contact you in case of a
              question or problem and to ensure correct payment.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              The legal basis for the data processing for this purpose lies in
              the fulfillment of an agreement in accordance with Art. 6 Par. 1
              lit. b GDPR.
            </StyledLgText>
          </div>
        </div>
        {/* Article 4 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            4. Other parties who have access to information we collect
          </StyledH4Heading>

          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              With the exception of the provider(s) of our Websites, we do not
              make your personal data available to third parties unless you have
              expressly consented to it, if we are legally obligated to, or if
              this is necessary to enforce our rights concerning a contractual
              relationship.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              Personal data collected via the Websites may be passed on to
              and/or accessed by the Website service provider(s). The Websites
              may be hosted on servers in the US. The transfer of data is for
              the purpose of providing and maintaining the functionality of our
              Websites. This is our legitimate interest in the sense of Art. 6
              Par. 1 lit f GDPR.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              If you pay by credit card through the Websites, we may forward
              your credit card information to the credit card issuer and the
              credit card acquirer. If you choose to pay by credit card, you may
              be asked to provide all the necessary information. The legal basis
              for passing on the data lies in the fulfillment of an agreement in
              the sense of Art. 6 Par. Lit. b GDPR.
            </StyledLgText>
          </div>
        </div>
        {/* Article 5 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            5. International transfer of personal data
          </StyledH4Heading>

          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              We are entitled to transfer your personal data to third parties
              abroad for the purposes of the data processing described in this
              Privacy Policy. This concerns especially the following
              receiver(s):
            </StyledLgText>
            <div className="ml-5 lg:ml-10 text-dark-gray">
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Our Websites service providers{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  E-commerce providers such as payment solution providers to
                  assist us in the processing of your online payments.{" "}
                </StyledMdText>
              </li>
            </div>
            <StyledLgText fontColor="text-dark-gray">
              They are obliged to protect data privacy to the same extent as we
              ourselves. If the level of data protection in a given country does
              not correspond to the Swiss and European data protection level, we
              contractually ensure that the protection of your personal data
              corresponds to that in Switzerland and the EU at all times by
              concluding agreements using the standard contractual clauses and
              complying with the GDPR.
            </StyledLgText>
          </div>
        </div>
        {/* Article 6 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            6. Data security
          </StyledH4Heading>

          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              We use appropriate technical and organizational security measures
              to protect your stored personal data against manipulation, partial
              or complete loss, and unauthorized access by third parties. Our
              security measures are continuously being improved in line with
              technical developments.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              Please note that any data transmission on the Internet (e.g.
              communication by e-mail) is generally not secure and we accept no
              liability for data transmitted to us via the Internet.
              Unfortunately, absolute protection is not technically possible.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              This information does not apply to the Websites of third parties
              and the corresponding links given on our Websites. The Foundation
              assumes no responsibility and liability for these.
            </StyledLgText>
          </div>
        </div>
        {/* Article 7 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            7. Your Rights regarding your data
          </StyledH4Heading>
          {/* Article 7 (7.1) */}
          <StyledH5Heading fontColor="text-black">
            7.1 Right to confirmation
          </StyledH5Heading>
          <div>
            <StyledLgText fontColor="text-dark-gray">
              You have the right to obtain confirmation from the Foundation as
              to whether or not personal data concerning you is being processed.
              If you wish to avail yourself of this right of confirmation, you
              may, at any time, contact the responsible person as stated in
              section 1 of this Privacy Policy.
            </StyledLgText>
          </div>
          {/* Article 7 (7.2) */}
          <StyledH5Heading fontColor="text-black">
            7.2 Right to access
          </StyledH5Heading>
          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              You have the right to obtain from the Foundation free information
              about your personal data stored at any time and a copy of this
              information. Furthermore, you will have access to the following
              information:
            </StyledLgText>
            <div className="ml-5 lg:ml-10 text-dark-gray">
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  The purposes of the processing{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  The categories of personal data concerned{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  The recipients or categories of recipients to whom the
                  personal data have been or will be disclosed, in particular
                  recipients in third countries or international organizations{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Where possible, the envisaged period for which the personal
                  data will be stored, or, if not possible, the criteria used to
                  determine that period{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  The existence of the right to request from the Foundation
                  rectification or erasure of personal data, or restriction of
                  processing of personal data concerning you, or to object to
                  such processing{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  The existence of the right to lodge a complaint with a
                  supervisory authority{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Where the personal data are not collected directly from you,
                  any available information as to their source{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  The existence of automated decision-making, including
                  profiling, referred to in Article 22(1) and (4) of the GDPR
                  and, at least in those cases, meaningful information about the
                  logic involved, as well as the significance and envisaged
                  consequences of such processing for you.{" "}
                </StyledMdText>
              </li>
            </div>
            <StyledLgText fontColor="text-dark-gray">
              If you wish to avail yourself of this right of access, you may at
              any time contact the responsible person as stated in section 1 of
              this Privacy Policy.
            </StyledLgText>
          </div>
          {/* Article 7 (7.3) */}
          <StyledH5Heading fontColor="text-black">
            7.3 Right to rectification
          </StyledH5Heading>
          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              You have the right to obtain from the Foundation, without undue
              delay, the rectification of inaccurate personal data concerning
              you. Taking into account the purposes of the processing, you shall
              have the right to have incomplete personal data completed,
              including by means of providing a supplementary statement. If you
              wish to exercise this right to rectification, you may, at any
              time, contact the responsible person as stated in section 1 of
              this Privacy Policy.
            </StyledLgText>
          </div>
          {/* Article 7 (7.4) */}
          <StyledH5Heading fontColor="text-black">
            7.4 Right to erasure (right to be forgotten)
          </StyledH5Heading>
          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              You have the right to obtain from the Foundation the erasure of
              personal data concerning you as soon as possible, and the
              Foundation shall have the obligation to erase personal data
              without undue delay where one of the following grounds applies:
            </StyledLgText>
            <div className="ml-5 lg:ml-10 text-dark-gray">
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  The personal data is no longer necessary in relation to the
                  purposes for which they were collected or otherwise processed{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  You withdraw consent to which the processing is based
                  according to point (a) of Article 6(1) of the GDPR, or point
                  (a) of Article 9(2) of the GDPR, and where there is no other
                  legal ground for the processing{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  The data subject objects to the processing pursuant to Article
                  21(1) of the GDPR and there are no overriding legitimate
                  grounds for the processing, or the data subject objects to the
                  processing pursuant to Article 21(2) of the GDPR{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  The personal data has been unlawfully processed{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  The personal data must be erased for compliance with a legal
                  obligation in accordance with the applicable law to which the
                  Foundation is subject{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  The personal data has been collected in relation to the offer
                  of information society services referred to in Article 8(1) of
                  the GDPR{" "}
                </StyledMdText>
              </li>
            </div>
            <StyledLgText fontColor="text-dark-gray">
              If any one of the aforementioned reasons applies, and you wish to
              request the erasure of personal data stored by the Foundation, you
              may at any time contact the responsible person as stated in
              section 1 of this Privacy Policy. The responsible person at the
              Foundation shall promptly ensure that the erasure request is
              complied with as soon as possible.
            </StyledLgText>
          </div>
          {/* Article 7 (7.5) */}
          <StyledH5Heading fontColor="text-black">
            7.5 Right to restriction of processing
          </StyledH5Heading>
          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              You have the right to obtain from the Foundation restriction of
              processing where one of the following applies:
            </StyledLgText>
            <div className="ml-5 lg:ml-10 text-dark-gray">
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  The accuracy of the personal data is contested by you, for a
                  period enabling the Foundation to verify the accuracy of the
                  personal data{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  The processing is unlawful and you oppose the erasure of the
                  personal data and requests instead the restriction of their
                  use instead{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  The Foundation no longer needs the personal data for the
                  purposes of the processing, but they are required by you for
                  the establishment, exercise or defense of legal claims{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  The Foundation no longer needs the personal data for the
                  purposes of the processing, but they are required by you for
                  the establishment, exercise or defense of legal claims{" "}
                </StyledMdText>
              </li>
            </div>
            <StyledLgText fontColor="text-dark-gray">
              If any one of the aforementioned conditions is met, and you wish
              to request the restriction of the processing of personal data
              stored by the Foundation, you may at any time contact the
              Foundation's responsible person. The responsible person will
              arrange the restriction of the processing.
            </StyledLgText>
          </div>
          {/* Article 7 (7.6) */}
          <StyledH5Heading fontColor="text-black">
            7.6 Right to object
          </StyledH5Heading>
          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              You have the right to object, on grounds relating to your
              particular situation, at any time, to the processing of personal
              data concerning you, which is based on point (e) or (f) of Article
              6(1) of the GDPR. This also applies to profiling based on these
              provisions.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              The Foundation shall no longer process the personal data in the
              event of the objection, unless the Foundation can demonstrate
              reasonable grounds for the processing, which override the
              interests, rights and freedoms of you, or for the establishment,
              exercise or defense of legal claims. In order to exercise the
              right to object, you may directly contact the responsible person.
            </StyledLgText>
          </div>
          {/* Article 7 (7.7) */}
          <StyledH5Heading fontColor="text-black">
            7.7 Automated individual decision-making, including profiling
          </StyledH5Heading>
          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              You have the right not to be subject to a decision based solely on
              automated processing, including profiling, which produces legal
              effects concerning you, or similarly significantly affects you, as
              long as the decision (1) is not necessary for entering into, or
              the performance of, a contract between you and the Foundation, or
              (2) is not authorized by the applicable law and which also lays
              down suitable measures to safeguard your rights and freedoms and
              legitimate interests, or (3) is not based on your explicit
              consent.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              If the decision (1) is necessary for entering into, or the
              performance of, a contract between you and the Foundation, or (2)
              it is based on your explicit consent, the Foundation shall
              implement suitable measures to safeguard your rights and freedoms
              and legitimate interests, at least the right to obtain human
              intervention on the part of the controller, to express their point
              of view and contest the decision.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              Please note that the Foundation does not use automatic
              decision-making but we may use profiling according to these
              Privacy Policy rules.
            </StyledLgText>
          </div>
          {/* Article 7 (7.8) */}
          <StyledH5Heading fontColor="text-black">
            7.8 Right to withdraw data protection consent
          </StyledH5Heading>
          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              You have the right to withdraw your consent to processing of your
              personal data at any time. If you wish to exercise the right to
              withdraw the consent, you may at any time directly contact the
              responsible person as stated in section 1.
            </StyledLgText>
          </div>
        </div>
        {/* Article 8 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            8. Duration of the storage
          </StyledH4Heading>

          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              The Foundation will process and store the personal data of the
              data subject only for the period necessary to achieve the purpose
              of storage, or as far as this is granted by the applicable laws or
              regulations. If the storage purpose is not applicable, or if a
              storage period prescribed by the applicable laws expires, the
              personal data is routinely erased in accordance with the legal
              requirements.
            </StyledLgText>
          </div>
        </div>
        {/* Article 9 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">9. Minors</StyledH4Heading>

          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              The Foundation does not knowingly collect or use any personal data
              from minors. A minor may be able to willingly share personal
              information with others, depending on the products and/or media
              channels used. If a minor provides us with their information
              without the consent of their parent or guardian, we will ask the
              parent or guardian to contact us for the purpose of deleting that
              information.
            </StyledLgText>
          </div>
        </div>
        {/* Article 10 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            10. Updates to our Privacy Policy
          </StyledH4Heading>

          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              The Foundation may update this Privacy Policy from time to time
              and inform you on the Websites that the policy has been amended.
              The current version of the Privacy Policy, as published on our
              Website, is applicable. With each update to our policies, we will
              note which sections have been updated.
            </StyledLgText>
          </div>
        </div>
        {/* Article 11 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            11. More information about privacy regulations
          </StyledH4Heading>

          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              For more information on applicable privacy regulations, you may
              refer to:
            </StyledLgText>
            <div className="ml-5 lg:ml-10 text-dark-gray">
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  EU General Data Protection Regulation:{" "}
                  <Link
                    className="text-yellow underline"
                    target="_blank"
                    to={
                      "https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=uriserv:OJ.L_.2016.119.01.0001.01.ENG"
                    }
                  >
                    https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=uriserv:OJ.L_.2016.119.01.0001.01.ENG
                  </Link>
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Swiss Federal Act on Data Protection:{" "}
                  <Link
                    className="text-yellow underline"
                    target="_blank"
                    to={
                      "https://www.admin.ch/opc/en/classified-compilation/19920153/index.html"
                    }
                  >
                    https://www.admin.ch/opc/en/classified-compilation/19920153/index.html
                  </Link>
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Swiss Ordinance to the Federal Act on Data Protection:{" "}
                  <Link
                    className="text-yellow underline"
                    target="_blank"
                    to={
                      "https://www.admin.ch/opc/en/classified-compilation/19930159/index.html"
                    }
                  >
                    https://www.admin.ch/opc/en/classified-compilation/19930159/index.html
                  </Link>
                </StyledMdText>
              </li>
            </div>
            <StyledLgText fontColor="text-dark-gray">
              <StyledLgText fontColor="text-dark-gray">
                For more information on applicable privacy regulations, you may
                refer to:{" "}
                <Link
                  className="text-yellow underline"
                  to={"mailto:support@getfits.io"}
                >
                  support@getfits.io
                </Link>
              </StyledLgText>
            </StyledLgText>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicySection;
