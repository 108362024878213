import React from 'react';

const BlogSkeleton = () => {
    return (
        <div className='flex flex-col w-full py-10'>
            <div className='flex flex-col w-full gap-4'>
                <div className='relative flex flex-col-reverse w-full gap-4'>
                    <div className='flex flex-row lg:flex-col lg:absolute lg:top-[25%] lg:left-[2.5%] items-end justify-center lg:w-[5%] gap-3'>
                        <div className='text-[#808184] bg-[#f5f5f5] border border-[#808184] p-2 rounded-[10px]'>
                            <div className='w-[25px] h-[25px] bg-gray-200 animate-pulse rounded-full'></div>
                        </div>
                        <div className='text-[#808184] bg-[#f5f5f5] border border-[#808184] p-2 rounded-[10px]'>
                            <div className='w-[25px] h-[25px] bg-gray-200 animate-pulse rounded-full'></div>
                        </div>
                        <div className='text-[#808184] bg-[#f5f5f5] border border-[#808184] p-2 rounded-[10px]'>
                            <div className='w-[25px] h-[25px] bg-gray-200 animate-pulse rounded-full'></div>
                        </div>
                        <div className='text-[#808184] bg-[#f5f5f5] border border-[#808184] p-2 rounded-[10px]'>
                            <div className='w-[25px] h-[25px] bg-gray-200 animate-pulse rounded-full'></div>
                        </div>
                    </div>
                    <div className='flex justify-center w-full'>
                        <div className='rounded-[18px] h-[300px] md:h-[400px] w-[672px] bg-gray-200 animate-pulse'></div>
                    </div>
                </div>
                <div className='w-full lg:w-[78%] self-center flex flex-col gap-3'>
                    <div className='flex items-center w-full gap-2'>
                        <div className='text-[#969696]'>
                            <div className='w-[15px] h-[15px] bg-gray-200 animate-pulse rounded-full'></div>
                        </div>
                        <div className='w-32 h-2 bg-gray-200 rounded animate-pulse'></div>
                    </div>
                    <div className='flex items-center justify-between w-full'>
                        <div className='w-32 h-2 bg-gray-200 rounded animate-pulse'></div>
                        <div className='w-32 h-2 bg-gray-200 rounded animate-pulse'></div>
                    </div>
                    <div className='w-full'>
                        <div className='w-full h-8 bg-gray-200 rounded animate-pulse'></div>
                    </div>
                    <div className='flex flex-col gap-4'>
                        <div className='w-full h-4 bg-gray-200 rounded animate-pulse'></div>
                        <div className='w-full h-4 bg-gray-200 rounded animate-pulse'></div>
                        <div className='w-full h-4 bg-gray-200 rounded animate-pulse'></div>
                        <div className='w-full h-4 bg-gray-200 rounded animate-pulse'></div>
                    </div>
                    <div className='flex flex-col w-full gap-4 pt-20'>
                        <div className='w-32 h-6 bg-gray-200 rounded animate-pulse'></div>
                        <div className='grid grid-cols-1 gap-3 md:grid-cols-2'>
                            <div className='w-full p-4 border border-gray-200 rounded shadow animate-pulse'>
                                <div className='flex items-center justify-center w-full h-48 mb-4 bg-gray-300 rounded'></div>
                                <div className='h-2.5 bg-gray-200 rounded-full w-48 mb-4'></div>
                                <div className='h-2 bg-gray-200 rounded-full mb-2.5'></div>
                                <div className='h-2 bg-gray-200 rounded-full mb-2.5'></div>
                                <div className='h-2 bg-gray-200 rounded-full'></div>
                                <div className='flex items-center mt-4'>
                                    <div className='w-10 h-10 mr-3 bg-gray-200 rounded-full'></div>
                                    <div>
                                        <div className='h-2.5 bg-gray-200 rounded-full w-32 mb-2'></div>
                                        <div className='w-48 h-2 bg-gray-200 rounded-full'></div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full p-4 border border-gray-200 rounded shadow animate-pulse'>
                                <div className='flex items-center justify-center w-full h-48 mb-4 bg-gray-300 rounded'></div>
                                <div className='h-2.5 bg-gray-200 rounded-full w-48 mb-4'></div>
                                <div className='h-2 bg-gray-200 rounded-full mb-2.5'></div>
                                <div className='h-2 bg-gray-200 rounded-full mb-2.5'></div>
                                <div className='h-2 bg-gray-200 rounded-full'></div>
                                <div className='flex items-center mt-4'>
                                    <div className='w-10 h-10 mr-3 bg-gray-200 rounded-full'></div>
                                    <div>
                                        <div className='h-2.5 bg-gray-200 rounded-full w-32 mb-2'></div>
                                        <div className='w-48 h-2 bg-gray-200 rounded-full'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlogSkeleton;
