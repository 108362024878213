import React from 'react'
import StyledH3Heading from '../../../common/components/styledH3Heading/StyledH3Heading'
import StyledXlText from '../../../common/components/styledXlText/StyledXlText'

const WhatWeProvideSec3 = () => {
    return (
        <div className='flex flex-col lg:flex-row gap-10 w-[90%] items-center py-10 lg:py-20'>
            <div className='bg-yellow rounded-[18px] flex flex-col gap-3 py-10 px-6 lg:w-[50%] h-max'>
                <div>
                    <StyledH3Heading fontColor="text-black">Quantified Income Rewards</StyledH3Heading>
                </div>
                <div>
                    <StyledXlText fontColor="text-black">Harnessing the expertise of our dedicated team of physicians, we've meticulously curated health and fitness protocols aligned with the esteemed guidelines of the American College of Sports Medicine. <br /><br />Our goal? Empowering you to effortlessly adopt a healthier lifestyle while reaping the rewards in functional crypto tokens – affectionately known as FITS (Functionised Integrative Tokens). Dive in now and embark on a journey towards optimal health and tangible rewards!</StyledXlText>
                </div>
            </div>
            <div className='lg:w-[50%] flex justify-center'>
                <img src='/assets/images/img-whatWeProvideSec3.png' alt='Section#2' />
            </div>
        </div>
    )
}

export default WhatWeProvideSec3
