export const content = {
    menuItems: {
        header: [
            {
                menu: "Home",
                link: "/",
            },
            {
                menu: "Blogs",
                link: "/blogs",
            },
            {
                menu: "FAQ",
                link: "/faq",
            },
            {
                menu: "Contact Us",
                link: "/contact-us",
            },
        ],
        footer: [
            {
                menu: "Home",
                link: "/",
            },
            {
                menu: "Blogs",
                link: "/blogs",
            },
            {
                menu: "FAQ",
                link: "/faq",
            },
            {
                menu: "Contact Us",
                link: "/contact-us",
            },
            {
                menu: "White Paper",
                link: "/whitepaper",
            },
            {
                menu: "Privacy Policy",
                link: "/privacy-policy",
            },
            {
                menu: "Terms & Conditions",
                link: "/terms-and-conditions",
            },
            {
                menu: "Cookies Policy",
                link: "/cookies-policy",
            },
            {
                menu: "Code Of Conduct",
                link: "/code-of-conduct",
            },
        ],
    },
    faqs: [
        {
            question: "What are $FITS and Why Are They Useful?",
            answer:
                "$FITS are the token rewards received daily for simply living a healthy lifestyle.  These rewards were designed to eliminate the global obesity pandemic, decrease insurance costs, improve human longevity, vitality and personal success, and be used as a medium of exchange. ",
        },
        {
            question: "What Devices Can I Connect to My $FITS App?",
            answer:
                "You can easily connect any wearable health device that pulls the metrics as identified to create rewards.  These devices include: Fit Bit, Garmin, and Apple Watch.",
        },
        {
            question: "How Are My Rewards Earned?",
            answer:
                "Your rewards are earned by living your daily lifestyle and achieving metrics chosen and created by the American College of Sports Medicine. The actual breakdown of these metrics can be found in the white paper, found on this website. ",
        },
        {
            question: "How Do I Connect My Wallet?",
            answer:
                "Your rewards will be deposited daily into your wallet in your app.  To transfer it to a different wallet, you can easily link it to your wallet of choice via Wallet Connect. ",
        },
        {
            question: "How Do I Spend My Rewards?",
            answer:
                "You will be able to spend your rewards for goods and services (as more retailers and wholesalers become part of the $FITS community), as well as donate to charities, and exchange for other crypto currencies on approved exchanges.",
        },
        {
            question: "What Do you Do With the Rewards I Do Not Earn?",
            answer:
                "The rewards you could potentially earn but choose not to will be burned.  This will keep the value of $FITS high and no one will benefit from efforts not attempted.  We believe in “earning your dinner”, which means to enjoy a benefit to look forward to as a result of efforts.  When efforts are not put forth, there is nothing to enjoy. ",
        },
        {
            question: "Is The $FITS App Free?",
            answer:
                "Yes!!! For all users, the $FITS App is Free for the first year.  It’s our way of letting you know we believe in this project and we believe in you.  After the first year, there is a renewal fee to subscribe, which will be extremely cost effective as you’ve already been earning this as part of your lifestyle.  You can literally subscribe after year one (1) by being you and not having to extend yourself in any unhealth or uncomfortable way. ",
        },
        {
            question: "What Is A Licensing Fee For?",
            answer:
                "The licensing fee is simply subscribing to the FITS app.  This is a way to be part of the community that changes the world each day by developing and achieving a healthy lifestyle for the rest of your life and will literally change the world.  We encourage you to be you and change the world!",
        },
        {
            question: "What Blockchain are $FITS Built on and Why?",
            answer:
                "Building a crypto token on the Polygon blockchain offers several benefits:<br /><br /><li><b>• Scalability</b>: Polygon is designed to address the scalability issues of Ethereum, offering faster and cheaper transactions compared to the Ethereum mainnet.</li><li><b>• Low Transaction Fees</b>: Transactions on the Polygon blockchain typically have lower fees compared to Ethereum, making it more cost-effective for users to interact with your token.</li><li><b>• Interoperability</b>: Polygon is compatible with Ethereum, allowing easy integration with existing Ethereum-based projects and tools.</li><li><b>• Security</b>: Polygon benefits from the security of the Ethereum network, leveraging Ethereum's robust consensus mechanism and ecosystem.</li><li><b>• Developer-Friendly</b>: Polygon provides developer-friendly tools and infrastructure, making it easier for developers to build and deploy decentralized applications (dApps) and tokens.</li><li><b>• Community Support:</b>: Polygon has a growing community of developers and users, providing resources and support for projects built on its blockchain.</li>",
        },
        {
            question: "Pretend I Have No Idea What You Just Said. Can You Explain This for Someone With Zero Knowledge of Blockchain Technology?",
            answer:
                "Sure thing! Here's a simpler version:<br /><br /> Building a crypto token on Polygon is great because it's like upgrading to a faster and cheaper version of Ethereum. Transactions cost less and happen quicker, which is good news for everyone using your token. Plus, it's safe and works well with Ethereum, so you can easily connect with other crypto stuff. Also, lots of people use Polygon, so you'll have support from a big community. Overall, it's a smart choice for launching your token, even if you're new to crypto.",
        },
        {
            question: "Why Not Reward People with Cash?",
            answer:
                "By empowering users with control, these communities foster stronger, fairer, and more robust ecosystems. With blockchain's transparency, practicality, and programmability, a new paradigm emerges. We firmly believe this shift is not just a passing trend but a permanent fixture that will increasingly define the future landscape.",
        },
        {
            question: "Who Built This And Why?",
            answer:
                "$FITS grew out of the mind of the mad scientist, James Goetz “Dr. Jim” as a legacy project in order to provide a long term solution to reduce pain and suffering caused by the global obesity pandemic.<br /><br /> This project draws significant inspiration from various crypto projects and companies. These entities have shown that token incentives offer an effective means of supplying resources in real-world user-owned IoT networks while conserving capital.",
        },
    ],
    socialData: [
        {
            img: "/assets/images/instagram.png",
            link: "https://www.instagram.com",
        },
        {
            img: "/assets/images/twitter.png",
            link: "https://twitter.com/realfitcoin",
        },
        {
            img: "/assets/images/facebook.png",
            link: "https://www.facebook.com",
        },
    ]
};

export const contactConfig = {
    YOUR_EMAIL: "gym@getfits.io",
    YOUR_SERVICE_ID: "service_cylhc5o",
    YOUR_TEMPLATE_ID: "template_sndyvbf",
    YOUR_USER_ID: "nokYeygKFRy6mTZKU",
};