import React from 'react'
import CookiesPolicySection from '../../sections/CookiesPolicy/CookiesPolicySection/CookiesPolicySection'

const CookiesPolicyPage = () => {
    return (
        <div>
            <CookiesPolicySection />
        </div>
    )
}

export default CookiesPolicyPage
