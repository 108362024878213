import React from 'react'
import FaqSection from '../../sections/Faq/FaqSection/FaqSection'

const FaqPage = () => {
    return (
        <div className='flex justify-center w-full'>
            <FaqSection />
        </div>
    )
}

export default FaqPage
