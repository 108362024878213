import axios from "axios";

export const API = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json; charset=utf-8",
    },
});

export const callApi = async (url, method = "get", payload, params = {}) => {
    try {
        const resp = await API({
            url,
            method,
            data: payload,
            params,
        });
        return resp;
    } catch (error) {
        // Handle error here or throw an exception
        console.error("API call error:", error);
        throw error;
    }
};
