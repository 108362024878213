import React from 'react'
import PerBlogPageSection from '../../../sections/Blogs/PerBlogPageSection/PerBlogPageSection'

const PerBlogPage = () => {
    return (
        <div className='flex justify-center w-full'>
            <PerBlogPageSection />
        </div>
    )
}

export default PerBlogPage
