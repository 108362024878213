import { createContext, useMemo, useState, useContext } from "react";

const StateContext = createContext();

export const StateContextProvider = ({ children }) => {
    const [loading, setLoading] = useState(false)

    const value = useMemo(() => {
        return {
            loading,
            setLoading,
        };
    }, [loading, setLoading]);

    return (
        <StateContext.Provider value={value}>
            {children}
        </StateContext.Provider>
    );
};

export const useStateContext = () => useContext(StateContext);
