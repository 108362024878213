import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../../../components/Button/Button";
import StyledH1Heading from "../../../common/components/styledH1Heading/StyledH1Heading";
import StyledH3Heading from "../../../common/components/styledH3Heading/StyledH3Heading";
import { contactConfig } from "../../../data";
import * as emailjs from "emailjs-com";

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export default function ContactSection() {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        subject: "",
        message: "",
        loading: false,
        show: false,
        alertmessage: "",
    });

    const [errors, setErrors] = useState({
        firstName: "",
        lastName: "",
        email: "",
        subject: "",
        message: "",
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });

        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));

        if (name === "firstName" || name === "lastName") {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: value.length >= 3 ? "" : `Minimum characters 3 for ${name}`,
            }));
        } else if (name === "email") {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: emailRegex.test(value) ? "" : "Invalid email",
            }));
        } else if (name === "subject") {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: value.length >= 5 ? "" : `Minimum characters 5 for ${name}`,
            }));
        } else if (name === "message") {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: value.length >= 5 ? "" : `Minimum characters 5 for ${name}`,
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormData({ ...formData, loading: true });

        // Scroll to the top of the page
        const isMobile = window.innerWidth <= 768;
        const options = isMobile ? { top: 0, behavior: "auto" } : { top: 0, behavior: "smooth" };
        window.scrollTo(options);

        const templateParams = {
            from_name: formData.email,
            to_name: contactConfig.YOUR_EMAIL,
            user_name: formData.firstName + " " + formData.lastName,
            message: formData.message,
            subject: formData.subject,
        };

        emailjs.send(
            contactConfig.YOUR_SERVICE_ID,
            contactConfig.YOUR_TEMPLATE_ID,
            templateParams,
            contactConfig.YOUR_USER_ID
        ).then(
            (result) => {
                setFormData({
                    ...formData,
                    loading: false,
                    alertmessage: "SUCCESS! Your enquiry has been sent.",
                    show: true,
                });
                toast.success("SUCCESS! Your enquiry has been sent.");
            },
            (error) => {
                setFormData({
                    ...formData,
                    alertmessage: `Failed to send!, ${error.text}`,
                    show: true,
                });
                toast.error(`Failed to send!, ${error.text}`);
            }
        );
    };

    return (
        <div className="flex flex-col w-[100%] justify-center items-center py-6 lg:py-10">
            <div className="flex flex-row w-full md:w-[80%] justify-center items-start gap-10">
                {/* heading section  */}
                <div className="flex flex-col w-full md:w-[70%] justify-between gap-5 md:gap-8 items-start">
                    <div className="flex flex-col w-[100%] justify-start items-center md:items-start md:py-4">
                        <StyledH1Heading fontColor="text-yellow md:text-black">Support Ticket</StyledH1Heading>
                    </div>
                    {/* Form Section */}
                    <div className=" flex w-[100%] md:w-[90%] justify-center shadow-xl bg-yellow md:bg-white md:rounded-[18px]">
                        <div className="flex flex-col w-[90%] md:w-[80%] gap-6 py-6">
                            <div className="flex flex-col items-center gap-2 md:items-start">
                                <StyledH3Heading fontColor="text-black">Fill in the form</StyledH3Heading>
                            </div>

                            <div className="flex flex-col xl:flex-row justify-between gap-4 w-[100%]">
                                <div className="flex flex-col w-full gap-1">
                                    <input
                                        placeholder="First Name"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        className="flex p-3 border rounded-md shadow-md focus:outline-none border-light-gray"
                                        maxLength={20}
                                    />
                                    {errors.firstName && (
                                        <p className="text-sm text-red-500">{errors.firstName}</p>
                                    )}
                                </div>
                                <div className="flex flex-col w-full gap-1">
                                    <input
                                        placeholder="Last Name"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        className="flex p-3 border rounded-md shadow-md focus:outline-none border-light-gray"
                                        maxLength={20}
                                    />
                                    {errors.lastName && (
                                        <p className="text-sm text-red-500">{errors.lastName}</p>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col justify-between gap-4">
                                <div className="flex flex-col gap-1">
                                    <input
                                        placeholder="Email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="flex p-3 border rounded-md shadow-md focus:outline-none border-light-gray"
                                        type="email"
                                        maxLength={52}
                                    />
                                    {errors.email && (
                                        <p className="text-sm text-red-500">{errors.email}</p>
                                    )}
                                </div>
                                <div className="flex flex-col gap-1">
                                    <input
                                        placeholder="Subject"
                                        name="subject"
                                        value={formData.subject}
                                        onChange={handleChange}
                                        className="flex p-3 border rounded-md shadow-md focus:outline-none border-light-gray"
                                        maxLength={20}
                                    />
                                    {errors.subject && (
                                        <p className="text-sm text-red-500">{errors.subject}</p>
                                    )}
                                </div>
                                <textarea
                                    rows="10"
                                    placeholder="Your Message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    className="flex p-3 border rounded-md shadow-md focus:outline-none border-light-gray"
                                    maxLength={500}
                                />
                                {errors.message && (
                                    <p className="text-sm text-red-500">{errors.message}</p>
                                )}
                            </div>
                            <div className="flex self-center">
                                <Button
                                    // isDisabled={isDisabled}
                                    text={`${formData.loading ? " Submitting..." : "Submit Ticket"}`}
                                    onClick={handleSubmit}
                                    bgColor="bg-white md:bg-yellow"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-col md:w-[30%] justify-between hidden gap-6 md:flex">
                    <img
                        src="assets/images/contactUs.png"
                        alt="Contact Us"
                        className="flex self-start "
                    />
                </div>
            </div>
            <div className={formData.loading ? "loading-bar" : ""}></div>
        </div>
    );
}
