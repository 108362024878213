import React, { useEffect, useState } from 'react'
import StyledH1Heading from '../../../common/components/styledH1Heading/StyledH1Heading'
import BlogsCard from '../../../components/blogsCard/BlogsCard'
import { callApi } from '../../../API/callApi'
import { useParams } from 'react-router-dom'
import TextSkeleton from '../../../components/textSkeleton/TextSkeleton'
import { useNavigate } from 'react-router-dom'
import CardSkeleton from '../../../components/cardSkeleton/CardSkeleton';
import { Helmet } from 'react-helmet'

const PerCategoryBlogPageSection = () => {

    const [categoryBlogsData, setCategoryBlogsData] = useState({ category: {}, blogs: [] })
    const params = useParams()
    const navigate = useNavigate()

    const getBlogCategoryData = async () => {
        try {
            const data = { slug: params.slug }
            const response = await callApi("blogs/get-blogs-by-category", "POST", data)
            setCategoryBlogsData(response.data.data)
        } catch (error) {
            if (error?.response?.data?.status === 404) {
                navigate("/404", { replace: true })
            }
            else if (error.message === "Network Error") {
                navigate("/500");
            }
            else {
                return error;
            }
        }
    }

    useEffect(() => {
        getBlogCategoryData()
    }, [])

    return (

        <>
            <Helmet>
                {categoryBlogsData.category?.name && <title>FITS | {categoryBlogsData.category?.name} Category</title>}
            </Helmet>

            <div className="py-10 w-[90%] relative flex flex-col gap-5 items-center">
                <div className="w-full lg:w-[80%] py-10 text-center bg-yellow rounded-xl">
                    <StyledH1Heading
                        fontColor={"text-black"}>{categoryBlogsData.category?.name ||
                            <div className="flex justify-center w-full animate-pulse">
                                <TextSkeleton width="w-52" />
                            </div>
                        }</StyledH1Heading>
                    <p className="w-11/12 mx-auto text-grey sm:w-9/12 lg:w-8/12">
                        Explore our latest blogs and articles on <span className="font-bold">{categoryBlogsData.category?.name}</span> category
                    </p>
                </div>
                <div className='grid w-full grid-cols-1 gap-5 md:w-[80%] md:grid-cols-2 xl:grid-cols-3 place-content-center'>
                    {categoryBlogsData.blogs?.length === 0 ?
                        <>
                            <CardSkeleton />
                            <CardSkeleton />
                            <CardSkeleton />
                            <CardSkeleton />
                            <CardSkeleton />
                            <CardSkeleton />
                        </>
                        :
                        categoryBlogsData.blogs?.map((item, index) => (
                            <BlogsCard data={item} key={index} />
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default PerCategoryBlogPageSection
