import React from "react";

// Files Import
import StyledMdText from "../../../common/components/styledMdText/StyledMdText";
import StyledLgText from "../../../common/components/styledLgText/StyledLgText";
import StyledH1Heading from "../../../common/components/styledH1Heading/StyledH1Heading";

const CookiesPolicySection = () => {
  return (
    <div className="py-5 lg:py-10">
      <div className="px-5 py-2 text-center lg:py-4 md:py-5">
        <StyledH1Heading fontColor="text-yellow">
          Cookie Policy
        </StyledH1Heading>
      </div>
      <div className="flex flex-col gap-5 border border-yellow rounded-[18px] py-5 lg:py-10 mx-auto w-11/12 md:w-3/4 lg:w-[62%]">
        {/* Article 1 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-4">
          <StyledLgText fontColor="text-dark-gray">
            Our Websites may use cookies to distinguish you from other users of
            our Websites. This may help us to provide you with a good experience
            when you browse our Websites and may also allow us to improve our
            Websites. By continuing to browse the Websites, you are agreeing to
            our use of cookies as well as the terms of this policy (the “Cookie
            Policy”). A cookie is a small file of letters and numbers that we
            may store on your browser or the hard drive of your computer if you
            agree. Cookies contain information that is transferred to your
            computer's hard drive. We may use the following cookies:
          </StyledLgText>
          <div className="ml-5 lg:ml-10 text-dark-gray">
            <li>
              {" "}
              <StyledMdText fontColor="text-dark-gray inline">
                {" "}
                <b>Strictly necessary cookies. </b> These are cookies that are
                required for the operation of our websites. They include, for
                example, cookies that enable you to log into secure areas of our
                websites, use a shopping cart or make use of e-commerce payment
                processing services.{" "}
              </StyledMdText>
            </li>
            <li>
              {" "}
              <StyledMdText fontColor="text-dark-gray inline">
                {" "}
                <b>Analytical/performance cookies. </b> They allow us to
                recognise and count the number of visitors and to see how
                visitors move around our websites when they are using it. This
                may help us to improve the way our websites work, for example,
                by ensuring that users are finding what they are looking for
                easily.{" "}
              </StyledMdText>
            </li>
            <li>
              {" "}
              <StyledMdText fontColor="text-dark-gray inline">
                {" "}
                <b>Functionality cookies. </b> These cookies are used to
                recognise you when you return to our websites. They may enable
                us to personalise our content for you, greet you by name, or
                remember your preferences (for example, your choice of language
                or region).{" "}
              </StyledMdText>
            </li>
            <li>
              {" "}
              <StyledMdText fontColor="text-dark-gray inline">
                {" "}
                <b>Targeting cookies </b> These cookies record your visit to our
                websites, the pages you have visited and the links you have
                followed.{" "}
              </StyledMdText>
            </li>
          </div>
          <StyledLgText fontColor="text-dark-gray">
            You can opt out of being tracked by Matomo Analytics and prevent the
            website from analysing the actions you take using the website. This
            will prevent us from learning from your actions and creating a
            better website experience for you and other users.
          </StyledLgText>
          <StyledLgText fontColor="text-dark-gray">
            You are not opted out. Uncheck this box to opt-out.
          </StyledLgText>
          <StyledLgText fontColor="text-dark-gray">
            You can find more information about the individual cookies we may
            use and the purposes for which we may use them in the table below:
          </StyledLgText>
          {/* Table */}
          <div className="overflow-auto no-scrollbar">
            <table className="[&>tr>th]:min-w-[200px] [&>tr>td]:min-w-[200px] border-collapse border border-black overflow-x-auto">
              <tr>
                <th className="w-1/10 border-b border-r p-2 text-left border-black">
                  Cookie
                </th>
                <th className="w-1/10 border-b border-r p-2 text-left border-black">
                  Name
                </th>
                <th className="w-6/10 border-b p-2 text-left border-black">
                  Purpose
                </th>
              </tr>
              <tr>
                <td className="w-1/10 border-r p-2 text-left border-black">
                  Matomo
                </td>
                <td className="w-1/10 border-r p-2 text-left border-black">
                  _pk_id
                </td>
                <td className="w-6/10 border-r p-2 text-left border-black">
                  Used to distinguish users. Enables us to identify which pages
                  are popular, estimate our audience size and usage pattern, and
                  better understand how the website is used.
                </td>
              </tr>
              <tr>
                <td className="w-1/10 border-r p-2 text-left border-black">
                  Matomo
                </td>
                <td className="w-1/10 border-r p-2 text-left border-black">
                  _pk_ses
                </td>
                <td className="w-6/10 border-r p-2 text-left border-black">
                  Used to store temporary session data. This is a short-lived
                  cookie, only lasting for a few minutes.
                </td>
              </tr>
            </table>
          </div>
          <StyledLgText fontColor="text-dark-gray">
            You can block cookies by activating the setting on your browser that
            allows you to refuse the setting of all or some cookies. However, if
            you use your browser settings to block all cookies (including
            essential cookies), you may not be able to access all or parts of
            our sites.
          </StyledLgText>
          <StyledLgText fontColor="text-dark-gray">
            Except for essential cookies, all cookies will expire after two
            years.
          </StyledLgText>
        </div>
      </div>
    </div>
  );
};

export default CookiesPolicySection;
