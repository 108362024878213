import React from "react";

// Files Import
import StyledMdText from "../../common/components/styledMdText/StyledMdText";
import StyledLgText from "../../common/components/styledLgText/StyledLgText";
import StyledH1Heading from "../../common/components/styledH1Heading/StyledH1Heading";
import StyledH4Heading from "../../common/components/styledH4Heading/StyledH4Heading";
import StyledH5Heading from "../../common/components/styledH5Heading/StyledH5Heading";
import { Link } from "react-router-dom";

// Files Import

const CodeOfConductSection = () => {
  return (
    <div className="py-5 lg:py-10">
      <div className="px-5 py-2 text-center lg:py-4 md:py-5">
        <StyledH1Heading fontColor="text-yellow">
          Code of Conduct
        </StyledH1Heading>
      </div>
      <div className="flex flex-col gap-5 border border-yellow rounded-[18px] py-5 lg:py-10 mx-auto w-11/12 md:w-3/4 lg:w-[62%]">
        {/* Article 1 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">1. Mission</StyledH4Heading>

          <div>
            <StyledLgText fontColor="text-dark-gray">
              To develop and maintain the most comprehensive and accessible
              knowledge hub for FITS and Functionised Blockchain Technology.
            </StyledLgText>
          </div>
        </div>
        {/* Article 2 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">2. Values</StyledH4Heading>

          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              The <Link target="_blank" to="https://getfits.io" className="text-yellow">getfits.io</Link> community
              strives to be:
            </StyledLgText>
            <div className="ml-5 lg:ml-10 text-dark-gray">
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Educational, intended to help everyone to understand FITS,
                  health and fitness from a science based perspective{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Inclusive{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Accessible{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Community-driven{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Focused on Functionised Blockchain Tehcnology’s underlying
                  technology and use-cases{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Focused on Functionised Blockchain Tehcnology’s concepts and
                  design principles{" "}
                </StyledMdText>
              </li>
            </div>
          </div>
        </div>
        {/* Article 3 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            3. What we are not
          </StyledH4Heading>

          <div className="ml-5 lg:ml-10 text-dark-gray">
            <li>
              {" "}
              <StyledMdText fontColor="text-dark-gray inline">
                {" "}
                The Functionised Blockchain Technology’s website{" "}
              </StyledMdText>
            </li>
            <li>
              {" "}
              <StyledMdText fontColor="text-dark-gray inline">
                {" "}
                A platform for promoting investments or profiteering of any kind{" "}
              </StyledMdText>
            </li>
            <li>
              {" "}
              <StyledMdText fontColor="text-dark-gray inline">
                {" "}
                A platform for elevating or endorsing individual projects or
                organizations{" "}
              </StyledMdText>
            </li>
            <li>
              {" "}
              <StyledMdText fontColor="text-dark-gray inline">
                {" "}
                A DEX, CEX or any other form of financial platform{" "}
              </StyledMdText>
            </li>
            <li>
              {" "}
              <StyledMdText fontColor="text-dark-gray inline">
                {" "}
                A platform that gives financial or legal advice of any kind{" "}
              </StyledMdText>
            </li>
          </div>
        </div>
        {/* Article 4 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH4Heading fontColor="text-black">
            4. Code of conduct
          </StyledH4Heading>
          {/* Article 4 (4.1) */}
          <StyledH5Heading fontColor="text-black">4.1 Pledge</StyledH5Heading>
          <div>
            <StyledLgText fontColor="text-dark-gray">
              Open participation is core to the{" "}
              <Link target="_blank" to="https://getfits.io" className="text-yellow">getfits.io</Link> ethos. We are a
              website and community maintained by contributors, and this is only
              possible if we maintain a welcoming, participatory environment. To
              this end, contributors to this site pledge to maintain a
              harassment-free environment for all participants across all{" "}
              <Link target="_blank" to="https://getfits.io" className="text-yellow">getfits.io</Link> platforms and
              community spaces. The{" "}
              <Link target="_blank" to="https://getfits.io" className="text-yellow">getfits.io</Link> {" "}
              community welcomes and values anyone who wants to participate in a
              constructive and friendly way, regardless of age, disability,
              ethnicity, sex characteristics, gender identity, level of
              experience, area of expertise, education, socio-economic status,
              nationality, personal appearance, race, religion or any other
              dimension of diversity.
            </StyledLgText>
          </div>
          {/* Article 4 (4.2) */}
          <StyledH5Heading fontColor="text-black">4.2 Scope</StyledH5Heading>
          <div>
            <StyledLgText fontColor="text-dark-gray">
              This Code of Conduct applies to all{" "}
              <Link target="_blank" to="https://getfits.io" className="text-yellow">getfits.io</Link> spaces (such as
              GitHub, Discord, Figma, Crowdin, Twitter and other online
              platforms), and it also applies when the community is represented
              in real-world public spaces such as at meetups, conferences and
              events.
            </StyledLgText>
          </div>
          {/* Article 4 (4.3) */}
          <StyledH5Heading fontColor="text-black">
            4.3 Our standards
          </StyledH5Heading>
          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              Examples of behavior that contributes to creating a positive
              environment include:
            </StyledLgText>
            <div className="ml-5 lg:ml-10 text-dark-gray">
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Using welcoming and inclusive language{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Being respectful of differing viewpoints and experiences{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Gracefully accepting and/or empathetically providing
                  constructive criticism{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Acting calmly and professionally when resolving conflicts or
                  disagreements{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Showing empathy and tolerance towards other community members{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Encouraging and amplifying new voices in the community{" "}
                </StyledMdText>
              </li>
            </div>
            <StyledLgText fontColor="text-dark-gray">
              Examples of unacceptable behavior by participants include:
            </StyledLgText>
            <div className="ml-5 lg:ml-10 text-dark-gray">
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Physical violence, threatening physical violence or
                  encouraging of physical violence of any kind{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Using sexualized language or imagery or imposing unwelcome
                  sexual attention{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Impersonating another individual or otherwise dishonestly
                  claiming affiliation with some individual or organization{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Trolling, insulting/derogatory comments, and personal or
                  political attacks{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Harassing other community members in public or private
                  channels{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Publishing others’ private information, such as a physical or
                  electronic address, without explicit permission{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Social engineering, scamming or otherwise manipulating other
                  community members{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Promoting investments, tokens, projects or anything else for
                  personal monetary or non-monetary gain{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Spamming servers with off-topic content{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Disregarding requests or warnings from community moderators{" "}
                </StyledMdText>
              </li>
              <li>
                {" "}
                <StyledMdText fontColor="text-dark-gray inline">
                  {" "}
                  Engaging in other conduct which could reasonably be considered
                  inappropriate in a professional setting{" "}
                </StyledMdText>
              </li>
            </div>
          </div>
          {/* Article 4 (4.4) */}
          <StyledH5Heading fontColor="text-black">
            4.4 Reporting
          </StyledH5Heading>
          <div className="flex flex-col gap-4">
            <StyledLgText fontColor="text-dark-gray">
              Violations of the code of conduct will normally be visible to the
              community as we try to do everything in open, public channels,
              allowing community members to self-police.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              However, if something happens that you feel needs attention, you
              can raise it with someone who has a moderation role (e.g. discord
              guide) so that they can help investigate and execute the
              appropriate response.
            </StyledLgText>
            <StyledLgText fontColor="text-dark-gray">
              When reporting, please include as much detail as possible,
              including specific examples and timestamps. This will help to
              ensure a fair outcome.
            </StyledLgText>
          </div>
          {/* Article 4 (4.5) */}
          <StyledH5Heading fontColor="text-black">
            4.5 Enforcement
          </StyledH5Heading>
          <div>
            <StyledLgText fontColor="text-dark-gray">
              Depending on the severity, people who violate the code of conduct
              can receive warnings, temporary bans or permanent bans from{" "}
              <Link target="_blank" to="https://getfits.io" className="text-yellow">getfits.io</Link> communities.
            </StyledLgText>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodeOfConductSection;
