import React from 'react'
import NotFoundSection from '../../sections/NotFound/NotFoundSection/NotFoundSection'

const NotFoundPage = () => {
    return (
        <div className="flex items-center justify-center w-full py-10">
            <NotFoundSection />
        </div>
    )
}

export default NotFoundPage
