import './App.css';
import Header from './layout/header/Header';
import Router from './router';
import ScrollToTop from './components/scrollToTop/ScrollToTop';
import Footer from './layout/footer/Footer';
import Loader from './components/loader/Loader';
import { useStateContext } from './context/StateContext';
import { useEffect } from 'react';
import { usePath } from './context/PathContext';
import { Helmet } from 'react-helmet';

function App() {

  const { path } = usePath();
  const { loading, setLoading } = useStateContext()

  useEffect(() => {
    setLoading(true);
    const loadingTimeout = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(loadingTimeout);

  }, [path]);

  return (
    <>
      <Helmet>
        <title>FITS | Your Fitness Companion</title>
      </Helmet>
      {loading === true ?
        (
          <Loader />
        ) :
        <div className='w-full max-w-[2140px] mx-auto'>
          <div className='flex justify-center bg-black'>
            <Header />
          </div>
          <style jsx="true">{`
    ::-webkit-scrollbar {
          width: 5px !important;
          background-color: transparent;
        }
        
        ::-webkit-scrollbar-thumb {
          background-color: #edbe00;
        }
      `}</style>
          <Router />
          <ScrollToTop />
          <div className='flex justify-center bg-dull-black lg:rounded-tl-[24px] lg:rounded-tr-[24px]'>
            <Footer />
          </div>
        </div>
      }
    </>
  );
}

export default App;
