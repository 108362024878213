import React from 'react'
import ContactSection from '../../sections/ContactUs/ContactUsSection/ContactSection'

const ContactPage = () => {
  return (
  <div className='w-full bg-contactUs'>
      <ContactSection />
    </div>
  )
}

export default ContactPage
