import React from 'react'
import StyledH4Heading from '../../../common/components/styledH4Heading/StyledH4Heading'
import StyledH1Heading from '../../../common/components/styledH1Heading/StyledH1Heading'
import StyledLgText from '../../../common/components/styledLgText/StyledLgText'
import StyledMdText from '../../../common/components/styledMdText/StyledMdText'
import { Link } from 'react-router-dom'

const HeroSection = () => {
    return (
        <div className='text-white w-[90%] h-full flex justify-between py-20 lg:py-32'>
            <div className='absolute top-0 left-0'>
                <img className='w-[100vw]' alt='Gradient' src='/assets/images/gradient.png' />
            </div>
            <div className='lg:w-[60%] lg:pl-20 z-10'>
                <div className='flex flex-col gap-10 lg:gap-20'>
                    <div className='flex flex-col gap-2'>
                        <div>
                            <StyledH4Heading fontColor="text-yellow">Your Fitness Companion</StyledH4Heading>
                        </div>
                        <div className='leading-tight'>
                            <StyledH1Heading fontColor="text-white">Transform Your <span className='text-yellow'>Exercise</span></StyledH1Heading>
                            <StyledH1Heading fontColor="text-white">Routine into <span className='text-yellow'>Crypto Rewards</span></StyledH1Heading>
                        </div>
                        <div className='lg:w-[90%] pt-5 lg:pt-0'>
                            <StyledLgText fontColor="text-white">Welcome to an unparalleled personal fitness revolution, where each stride, every repetition, and even the smallest bead of perspiration is met with rewarding incentives. Embark on a transformative fitness journey and convert your daily exercise regimen into a lucrative pathway to optimal health and abundant wealth.</StyledLgText>
                        </div>
                    </div>
                    <div className='flex flex-col gap-5'>
                        <div className='text-center lg:text-left'>
                            <StyledMdText fontColor="text-white">Coming soon on</StyledMdText>
                        </div>
                        <div className='flex gap-5 lg:z-30'>
                            <Link target='__blank' to="https://play.google.com/store/games?hl=en&gl=US">
                                <img width="211.502px" height="64.847px" src='/assets/images/googleplay.png' alt='Google Play Store' />
                            </Link>
                            <Link target='__blank' to="https://www.apple.com/app-store/">
                                <img width="211.502px" height="64.847px" src='/assets/images/applestore.png' alt='Google Play Store' />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='absolute bottom-0 left-0'>
                <img className='w-[100vw]' alt='Gradient' src='/assets/images/earnRewards.png' />
            </div>
        </div>
    )
}

export default HeroSection
