import React from "react";

export default function Button({ isDisabled, text, onClick, bgColor }) {
  return (
    <button
      onClick={onClick}
      disabled={isDisabled}
      className={`text-black cursor-pointer rounded-full py-4 px-10 text-md leading-[120%] text-center font-semibold transition duration-300 ease-in-out hover:scale-105
          disabled:cursor-not-allowed disabled:hover:scale-100 disabled:bg-hero-section-bgGradient ${bgColor}`}
    >
      {text}
    </button>
  );
}
