import React from 'react'
import BlogsSection from '../../sections/Blogs/BlogsSection/BlogsSection'

const BlogsPage = () => {
    return (
        <div className='flex justify-center w-full'>
            <BlogsSection />
        </div>
    )
}

export default BlogsPage
