import React from 'react'
import StyledLgText from '../../../common/components/styledLgText/StyledLgText'
import Button from '../../../components/Button/Button'
import { Link } from 'react-router-dom'

const ServerErrorSection = () => {
    return (
        <div className='w-[90%] lg:w-[70%] border border-yellow rounded-3xl text-black flex flex-col gap-5 items-center py-10'>
            <div>
                <img src='/assets/images/500.png' alt='500' />
            </div>
            <div className='w-[90%] lg:w-[60%] text-center'>
                <StyledLgText fontColor="text-dark-gray">Server encountered an error and couldn't complete your request. <br />Click below button to go back to home</StyledLgText>
            </div>
            <Link to="/">
                <Button
                    text="Go Back Home"
                    bgColor="bg-yellow"
                />
            </Link>
        </div>
    )
}

export default ServerErrorSection
