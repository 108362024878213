import React from 'react'
import HeroSection from '../../sections/Home/HeroSection/HeroSection'
import EarnRewardsSection from '../../sections/Home/EarnRewardsSection/EarnRewardsSection'
import WhatWeProvideSec1 from '../../sections/Home/WhatWeProvideSec1/WhatWeProvideSec1'
import WhatWeProvideSec2 from '../../sections/Home/WhatWeProvideSec2/WhatWeProvideSec2'
import WhatWeProvideSec3 from '../../sections/Home/WhatWeProvideSec3/WhatWeProvideSec3'
import RewardsSection from '../../sections/Home/RewardsSection/RewardsSection'
import SocialCommunitySection from '../../sections/Home/SocialCommunitySection/SocialCommunitySection'

const HomePage = () => {
    return (
        <div className='w-full'>
            <div className='flex justify-center !bg-dull-black bg-heroSection relative'>
                <HeroSection />
            </div>
            <div className='flex justify-center'>
                <WhatWeProvideSec1 />
            </div>
            <div className='flex justify-center'>
                <WhatWeProvideSec2 />
            </div>
            <div className='flex justify-center'>
                <WhatWeProvideSec3 />
            </div>
            <div className='flex justify-center'>
                <EarnRewardsSection />
            </div>
            <div className='flex justify-center bg-yellow'>
                <RewardsSection />
            </div>
            <div className='flex justify-center'>
                <SocialCommunitySection />
            </div>
        </div>
    )
}

export default HomePage
