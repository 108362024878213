import React from 'react'
import StyledH1Heading from '../../../common/components/styledH1Heading/StyledH1Heading'
import StyledXlText from '../../../common/components/styledXlText/StyledXlText'

const RewardsSection = () => {
    return (
        <div className='w-[90%] flex flex-col-reverse lg:flex-row gap-10 lg:gap-20 pt-10'>
            <div className='lg:w-[50%] flex justify-center'>
                <img src='/assets/images/rewardsTabPhone.png' alt='rewards' />
            </div>
            <div className='lg:w-[45%]'>
                <div className='bg-black rounded-[33px] py-6 px-6 flex flex-col gap-3'>
                    <div>
                        <StyledH1Heading fontColor="text-white !leading-tight">Get Fit<br /> Earn $FITS</StyledH1Heading>
                    </div>
                    <div>
                        <StyledXlText fontColor="text-white !font-normal">Earn Crypto for Your Exercise Efforts</StyledXlText>
                    </div>
                    <div className='border-2 border-yellow rounded-[23px] p-4 mt-3'>
                        <StyledXlText fontColor="text-white !font-normal">Unlock the potential of your FITS rewards wallet within the app, granting you the versatility to securely store, spend, or transfer your hard-earned crypto tokens to any preferred third-party exchange or cold wallet. <br /><br />Your efforts yield tangible rewards, so dive in and make the most of your crypto tokens today!</StyledXlText>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RewardsSection
