import React from "react";
import Faqs from "../../../components/Faqs/Faqs";
import StyledH1Heading from "../../../common/components/styledH1Heading/StyledH1Heading";
import { content } from "../../../data";
import StyledMdText from "../../../common/components/styledMdText/StyledMdText";
import { Link } from "react-router-dom";

export default function FaqSection() {

    const faqs = content.faqs;

    return (
        <div className="py-10 w-[90%] flex flex-col gap-5 items-center">
            <div className="py-10 text-center bg-yellow lg:w-[80%] rounded-xl">
                <StyledH1Heading
                    fontColor={"text-black"}>Frequently Asked Questions</StyledH1Heading>
                <p className="w-11/12 mx-auto font-inter text-grey sm:w-9/12 lg:w-8/12">
                    You can find your answers to your problems here or simply search for
                    the exact question, you have in your mind.
                </p>
            </div>
            <div className="w-full lg:w-[80%] flex flex-col gap-2">
                {faqs.map((item, index) => (
                    <div key={index}>
                        <Faqs item={item} />
                    </div>
                ))}
            </div>
            <div className="pt-5">
                <StyledMdText fontColor="text-black">Didn’t find what you are looking for? <Link className="underline hover:text-yellow" to="/contact-us">Contact Us</Link></StyledMdText>
            </div>
        </div>
    );
}
