import React from 'react'
import { Link } from 'react-router-dom'
import StyledSmText from '../../common/components/styledSmText/StyledSmText'
import { content } from '../../data'
import { usePath } from '../../context/PathContext'
const Footer = () => {
    const currentYear = new Date().getFullYear();
    const socialLinks = content.socialData;
    const footer = content.menuItems.footer;
    const pathname = usePath()
    return (
        <div className='w-[90%] md:w-[70%] flex flex-col gap-3'>
            <div className='flex flex-col justify-between gap-10 py-10 md:gap-0 md:py-16 md:flex-row'>
                <div className='flex flex-col gap-8'>
                    <Link to="/">
                        <img width="100px" src='/assets/images/logo.png' alt='logo' />
                    </Link>
                    <div className='flex gap-3'>
                        {
                            socialLinks.map((item, index) => {
                                return (
                                    <Link target='__blank' className='duration-500 hover:-translate-y-1' to={item.link} key={index}>
                                        <img src={item.img} alt={item.img} />
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='flex gap-10'>
                    <div className='flex flex-col gap-5'>
                        {
                            footer?.slice(0, 4)?.map((item, index) => (
                                <Link className={`hover:text-yellow ${pathname.path === item?.link ? "text-yellow" : "text-[#faefc3]"}`} key={index} to={item.link}>{item.menu}</Link>
                            ))
                        }
                    </div>
                    <div className='flex flex-col gap-5'>
                        {
                            footer?.slice(4)?.map((item, index) => (
                                <Link className={`hover:text-yellow ${pathname.path === item?.link ? "text-yellow" : "text-[#faefc3]"}`} key={index} to={item.link}>{item.menu}</Link>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className='w-full'>
                <hr className='border-white border-opacity-20' />
            </div>
            <div className='pb-3 text-center'>
                <StyledSmText fontColor="text-light-gray">Copyright &#169;{currentYear} Functionised Blockchain Technology Inc. | All rights reserved.</StyledSmText>
            </div>
        </div>
    )
}

export default Footer
