import React from 'react'
import TermsNConditionSection from '../../sections/TermsNConditions/TermsNConditionsSection/TermsNConditionsSection'

const TermsNConditionsPage = () => {
    return (
        <div>
            <TermsNConditionSection />
        </div>
    )
}

export default TermsNConditionsPage
