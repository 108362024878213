import React from 'react'
import CodeOfConductSection from '../../sections/CodeOfConduct/CodeOfConductSection'

const CodeOfConductPage = () => {
    return (
        <div>
            <CodeOfConductSection />
        </div>
    )
}

export default CodeOfConductPage
