import React from "react";
import "./Skeleton.css";

const Skeleton = () => {
  return (
    <div className="w-full flex flex-wrap h-full">
      <span className="skeleton-box h-full w-full inline-block rounded-[18px]"></span>
    </div>
  );
};

export default Skeleton;
