import React from 'react'
import StyledLgText from '../../../common/components/styledLgText/StyledLgText'
import Button from '../../../components/Button/Button'
import { Link } from 'react-router-dom'

const NotFoundSection = () => {
    return (
        <div className='w-[90%] lg:w-[70%] border border-yellow rounded-3xl text-black flex flex-col gap-5 items-center py-10'>
            <div>
                <img src='/assets/images/404.png' alt='404' />
            </div>
            <div className='w-[90%] lg:w-[60%] text-center'>
                <StyledLgText fontColor="text-dark-gray">The page you are looking for is broken, or deleted by someone. <br />Click below button to go back to home</StyledLgText>
            </div>
            <Link to="/">
                <Button
                    text="Go Back Home"
                    bgColor="bg-yellow"
                />
            </Link>
        </div>
    )
}

export default NotFoundSection
